import { IResponseData } from "@/utilities/types/requests.ts";
import useApi from "@/hooks/useApi.tsx";
import { useTableServer } from "@/hooks/useTableServer.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import ControlledTable from "@/components/controlled-table";
import { useColumn } from "@/hooks/use-column.ts";
import { Roles } from "@/utilities/types/Users.ts";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { Button, Text, Title } from "rizzui";
import { backPrev } from "@/utilities/functions";
import { routes } from "@/config/routes";
import { GetColumns } from "./columns";
import UnitsRepository from "@/utilities/repositories/Units";
import { IUnit, IUnitDTO } from "@/utilities/types/Units";
import cn from "@/utils/class-names";
import useProfile from "@/hooks/use-profile";

const UnitsTable = () => {
  const { projectId, floorPlanId } = useParams<{
    projectId: string;
    floorPlanId: string;
  }>();

  const onHeaderCellClick = (value: string) => ({
    onClick: () => {
      handleSort(value);
    },
  });

  const { profile } = useProfile();

  const { request: createUnit } = useApi<IResponseData<IUnit>>({
    request: UnitsRepository.createUnit,
    enableToast: true,
  });

  const { request: updateUnit } = useApi<IResponseData<IUnit>>({
    request: UnitsRepository.updateUnit,
    enableToast: true,
  });

  const { request: deleteUnitItem } = useApi<IResponseData<IUnit>>({
    request: UnitsRepository.removeUnit,
    enableToast: true,
  });

  const {
    handleReset,
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handleSort,
    sortConfig,
    handlePaginate,
    pageSize,
    handleChangePageSize,
    response,
  } = useTableServer({
    server: {
      request: UnitsRepository.getUnits,
    },
    initialFilterState: {
      projectId,
      floorPlanId,
    },
  });

  const [customTableData, setCustomTableData] =
    useState<(IUnit & { isAdd?: boolean })[]>(tableData);

  const [isAdding, setIsAdding] = useState<boolean>(false);

  const onAddUnit = useCallback(
    async (data: IUnitDTO) => {
      await createUnit({
        ...data,
        floorPlanId,
        projectId,
      })
        .then(() => {
          setIsAdding(false);
          handleReset(); // Recall API
        })
        .catch((err: any) => {
          toast.error(err?.message || "Something went wrong");
        });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [createUnit, floorPlanId, handleReset, projectId]
  );

  const onUpdateItem = useCallback(
    async (id: string, data: Partial<IUnitDTO>) => {
      await updateUnit(id, data);
      handleReset(); // Recall API
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [handleReset, updateUnit]
  );

  const onDeleteItem = useCallback(
    async (id: string) => {
      if (isAdding) {
        setCustomTableData(tableData);
        setIsAdding(false);
        return;
      }
      await deleteUnitItem(id);
      handleReset(); // Recall API
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [deleteUnitItem, handleReset, isAdding, tableData]
  );

  useEffect(() => {
    setCustomTableData(tableData);
  }, [tableData]);

  const columns = useMemo(
    () =>
      GetColumns({
        data: customTableData,
        sortConfig,
        onDeleteItem,
        onHeaderCellClick,
        projectId: projectId ?? "",
        reset: handleReset,
        role: Roles.ADMIN,
        onUpdateItem,
        isAdding,
        onAddUnit,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onHeaderCellClick,
      onDeleteItem,
      onAddUnit,
      sortConfig.key,
      sortConfig.direction,
      customTableData,
      onUpdateItem,
    ]
  );

  const addNewRow = () => {
    setIsAdding(true);
    setCustomTableData([
      {
        ...customTableData[0],
        isAdd: true,
        id: "addNew",
        name: "",
        status: "available",
        price: 0,
      },

      ...customTableData,
    ]);
  };

  const { visibleColumns } = useColumn(columns);
  return (
    <div className={"col-span-full mt-4"}>
      <div className="mb-4 w-full flex items-center justify-between">
        <Title as="h5">Units</Title>
        <div>
          <Link
            to={backPrev(routes.project.listing)}
            className={"mr-2 ml-auto"}
          >
            <span
              className={
                "px-4 py-2 inline-block border border-solid border-black rounded-md"
              }
            >
              Back
            </span>
          </Link>
          {profile?.role === Roles.ADMIN && (
            <Button disabled={isAdding} onClick={addNewRow}>
              Add new
            </Button>
          )}
        </div>
      </div>
      <div className={"col-span-full mt-4"}>
        <div className="mb-4 w-full flex gap-x-2 items-center">
          <div className="flex flex-row items-center gap-x-2">
            <Title
              as="h6"
              className="text-xs font-semibold uppercase  xs:text-sm"
            >
              Total:
            </Title>
            <Text className="leading-[1.7]">{totalItems} units.</Text>
          </div>
          <div className="flex flex-row items-center gap-x-2">
            <Title
              as="h6"
              className="text-xs font-semibold uppercase  xs:text-sm"
            >
              Available:
            </Title>
            <Text className="leading-[1.7]">
              {response?.data?.availableUnitCount} units.
            </Text>
          </div>
        </div>
      </div>
      <ControlledTable
        variant="modern"
        data={customTableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={visibleColumns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        rowClassName={cn(
          isAdding &&
            "data-[row-key=addNew]:opacity-100 data-[row-key=addNew]:pointer-events-auto opacity-20 pointer-events-none"
        )}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
};

export default UnitsTable;
