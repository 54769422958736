import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Input, Button, ActionIcon } from "rizzui";

import generateParsedMessage from "@/utilities/functions/generate-parsed-message";
import toast from "react-hot-toast";
import formatPhone from "@/utilities/functions/format-phone";
import { IProject } from "@/utilities/types/Project";
import { IUserData } from "@/utilities/types/Users";
import { whatsAppDataSchema } from "@/utils/validators/whats-app.scheme";
import { zodResolver } from "@hookform/resolvers/zod";
import { IoReloadOutline } from "react-icons/io5";
import { MdClear } from "react-icons/md";

interface IWhatsAppFormValue {
  name?: string;
  phone?: string;
  email?: string;
  user?: IUserData;
  project?: IProject;
  appointment?: string;
  templateContent: string;
}

type Props = {
  name?: string;
  email?: string;
  phone?: string;
  templateContent: string;
  project?: IProject; // Replace with your project type
  agent: IUserData; // Replace with your agent type
  onSuccess?: () => void;
  onRemove?: () => void;
};

function keepNumbersAndPlus(input: string) {
  return input.replace(/[^\d+]/g, "");
}

const OpenContact = (phone: string | undefined, content: string) => {
  const encodedMessage = encodeURIComponent(content);
  window.open(
    `https://api.whatsapp.com/send/?phone=${keepNumbersAndPlus(phone)}&text=${encodedMessage}&type=phone_number&app_absent=0`,
    "_blank"
  );
};

export default function WhatsAppSender({
  name = "",
  email = "",
  phone = "",
  templateContent,
  project,
  agent,
  onRemove,
  onSuccess,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const { watch, formState, control, handleSubmit } = useForm({
    resolver: zodResolver(whatsAppDataSchema),
    defaultValues: {
      name,
      email,
      phone,
      templateContent,
      project,
      agent,
    },
  });

  const onSubmit = async (values: IWhatsAppFormValue) => {
    try {
      setIsLoading(true);

      values.templateContent = watch("templateContent");

      if (!values?.templateContent) {
        throw new Error(JSON.stringify(formState?.errors));
      }

      const message = generateParsedMessage(templateContent, {
        name: values.name || "",
        phone: values.phone || "",
        email: values.email || "",
        user: agent,
        project,
        appointment: "",
      });

      OpenContact(
        values.phone?.startsWith("0")
          ? `+65${values.phone?.trim()?.slice(1)}`
          : values.phone?.trim(),
        message
      );

      toast.success("A message has been sent to the lead");
      onSuccess?.();
      setIsLoading(false);
      setIsSent(true);
    } catch (err) {
      toast.error(err.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-1 lg:grid-cols-9 gap-3"
    >
      <div className="col-span-full lg:col-span-3 flex justify-center items-center gap-2">
        <ActionIcon
          size="sm"
          variant="text"
          rounded="full"
          className="shrink-0 hidden lg:block"
          onClick={onRemove}
        >
          <MdClear className="w-5 h-5" />
        </ActionIcon>
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <Input
              className="w-full"
              type="text"
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
      </div>
      <div className="col-span-full lg:col-span-3">
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <Input type="email" {...field} error={fieldState.error?.message} />
          )}
        />
      </div>
      <div className="col-span-full lg:col-span-2">
        <Controller
          control={control}
          name="phone"
          render={({ field, fieldState }) => (
            <Input
              type="text"
              {...field}
              value={formatPhone(field.value, "+65")}
              error={fieldState.error?.message}
            />
          )}
        />
      </div>
      <div className="lg:col-span-1 col-span-full flex justify-center items-center gap-2">
        <ActionIcon
          size="sm"
          variant="text"
          rounded="full"
          className="shrink-0 lg:hidden block"
          onClick={onRemove}
        >
          <MdClear className="w-5 h-5" />
        </ActionIcon>
        <Button
          type="submit"
          isLoading={isLoading}
          disabled={!formState?.isValid}
          className="w-full lg:w-fit px-2"
          variant={isSent ? "outline" : "solid"}
        >
          <span className="flex gap-1 items-center">
            {!isSent ? (
              <>
                <FaWhatsapp /> Send
              </>
            ) : (
              <>
                <IoReloadOutline />
                Resend
              </>
            )}
          </span>
        </Button>
      </div>
    </form>
  );
}
