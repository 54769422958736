import { IResponseData } from "@/utilities/types/requests.ts";
import { IFloor } from "@/utilities/types/Floor.ts";
import FloorPlansRepository from "@/utilities/repositories/FloorPlans.ts";
import useApi from "@/hooks/useApi.tsx";
import { useTableServer } from "@/hooks/useTableServer.tsx";
import { useCallback, useMemo } from "react";
import ControlledTable from "@/components/controlled-table";
import { useColumn } from "@/hooks/use-column.ts";
import { GetColumns } from "@/app/app-shared/floor-plan/table/columns.tsx";
import { Roles } from "@/utilities/types/Users.ts";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import ModalCreateEditFloor from "@/app/app-shared/floor-plan/create-edit/modal-edit.tsx";
import { Title } from "rizzui";
const FloorPlanTable = () => {
  const { id: projectId } = useParams<{ id: string }>();
  const onHeaderCellClick = (value: string) => ({
    onClick: () => {
      handleSort(value);
    },
  });

  const { request: createFloor } = useApi<IResponseData<IFloor>>({
    request: FloorPlansRepository.createFloor,
    enableToast: true,
  });

  const { request: deleteFloorItem } = useApi<IResponseData<IFloor>>({
    request: FloorPlansRepository.RemoveFloor,
    enableToast: true,
  });

  const {
    handleReset,
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handleSort,
    handlePaginate,
    pageSize,
    handleChangePageSize,
  } = useTableServer({
    server: {
      request: FloorPlansRepository.getFloor,
    },
    initialFilterState: {
      projectId,
    },
  });

  const onAddFloor = useCallback(async () => {
    await createFloor()
      .then(() => {
        handleReset(); // Recall API
      })
      .catch((err: any) => {
        toast.error(err?.message || "Something went wrong");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleReset]);

  const onDeleteItem = useCallback(
    async (id: string) => {
      await deleteFloorItem(id);
      handleReset(); // Recall API
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [handleReset]
  );

  const columns = useMemo(
    () =>
      GetColumns({
        data: tableData,
        onDeleteItem,
        onHeaderCellClick,
        projectId: projectId ?? "",
        reset: handleReset,
        role: Roles.ADMIN,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onHeaderCellClick, onDeleteItem, onAddFloor, tableData]
  );
  const { visibleColumns } = useColumn(columns);
  return (
    <div className={"col-span-full mt-4"}>
      <div className=" w-full flex items-center justify-between">
        <Title as="h5">Floorplan</Title>
        {projectId && (
          <ModalCreateEditFloor reset={handleReset} projectId={projectId} />
        )}
      </div>
      <ControlledTable
        scroll={{ x: true }}
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // @ts-ignore
        columns={visibleColumns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
};

export default FloorPlanTable;
