import ControlledTable from "@/components/controlled-table";
import { useTableServer } from "@/hooks/useTableServer";
import {
  ReactNode,
  use,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import FilterElement from "./filter-element";
import EnquiryEntriesRepository from "@/utilities/repositories/EnquiryEntries";
import { IEnquiryEntry } from "@/utilities/types/EnquiryEntry";
import useProfile from "@/hooks/use-profile";
import { IUserData, Roles } from "@/utilities/types/Users";
import { useModal } from "@/app/shared/modal-views/use-modal";
import { getColumns } from "./columns";
import {
  Title,
  Select,
  Textarea,
  Text,
  Empty,
  Accordion,
  ActionIcon,
  AccordionProps,
} from "rizzui";
import { TAgencySetting } from "@/utilities/types/Setting";
import SettingsRepository from "@/utilities/repositories/Settings";
import MessageRepository from "@/utilities/repositories/Message";
import OptionItemLayout from "../../homepage-layout/forms/OptionItemLayout";
import formatPhone from "@/utilities/functions/format-phone";
import WhatsAppSender from "./whatsapp-sender";
import { IProject } from "@/utilities/types/Project";
import { LuUsers } from "react-icons/lu";
import {
  PiBookOpenUser,
  PiWhatsappLogoFill,
  PiWhatsappLogoLight,
} from "react-icons/pi";
import { MdOutlineClearAll } from "react-icons/md";
import { useAdvanceFeatures } from "@/hooks/use-advance-features";
import cn from "@/utils/class-names";

interface TSelectMessage {
  label: string;
  value: string;
  content: string;
  isAdmin: boolean;
}

interface TSelectOptionMessage {
  label: string;
  value: string;
}

function findItemsById(tableData: IEnquiryEntry[], selectedRowKeys: string[]) {
  return tableData.filter((item) => selectedRowKeys.includes(item.id));
}

export default function EnquiryEntriesTable() {
  const { request: onDelete } = useApi<IResponseData<IEnquiryEntry>>({
    request: EnquiryEntriesRepository.deleteItem,
    enableToast: true,
  });

  const { profile } = useProfile();
  const { multipleContact } = useAdvanceFeatures();

  const {
    isLoading,
    tableData,
    currentPage,
    totalItems,
    handlePaginate,
    pageSize,
    handleChangePageSize,
    searchTerm,
    handleSearch,
    sortConfig,
    handleSort,
    handleReset,
    isFiltered,
    filters,
    updateFilter,
    selectedRowKeys,
    handleRowSelect,
    handleSelectAll,
  } = useTableServer({
    server: {
      request:
        profile?.role === Roles.AGENCY
          ? EnquiryEntriesRepository.getListByAgency
          : EnquiryEntriesRepository.getList,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
  });

  const onHeaderCellClick = useCallback(
    (value: string) => ({
      onClick: () => {
        handleSort(value);
      },
    }),
    [handleSort]
  );

  const onDeleteItem = useCallback(
    async (id: string) => {
      await onDelete(id);
      handleReset();

      // Recall API
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [handleReset, onDelete]
  );

  const columns = useMemo(
    () =>
      getColumns({
        data: tableData,
        sortConfig,
        onHeaderCellClick,
        onDeleteItem,
        isAdmin: profile?.role === Roles.ADMIN,
        checkedItems: selectedRowKeys,
        onChecked: handleRowSelect,
        handleSelectAll,
        multipleContact,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tableData,
      sortConfig,
      onHeaderCellClick,
      onDeleteItem,
      profile?.role,
      selectedRowKeys,
      handleRowSelect,
      handleSelectAll,
    ]
  );

  const { openModal } = useModal();

  const [isOpen, setIsOpen] = useState(false);

  const { request: getAgencySetting, response: agencySettingData } = useApi<
    IResponseData<TAgencySetting>
  >({
    request: SettingsRepository.getAgencySetting,
  });

  const [messageSelected, setMessageSelected] = useState<TSelectMessage>();
  const [valueMessage, setValueMesagge] = useState<string>();
  const [optionsMessage, setOptionsMessage] = useState<TSelectOptionMessage[]>(
    []
  );
  const templateRef = useRef<HTMLTextAreaElement>(null);
  const formWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const adjustTextareaHeight = () => {
      if (templateRef.current && formWrapperRef.current) {
        const formWrapperBottom =
          formWrapperRef.current.getBoundingClientRect().bottom;
        const templateTop = templateRef.current.getBoundingClientRect().top;
        const availableHeight = Math.abs(formWrapperBottom - templateTop);

        if (availableHeight > 0) {
          templateRef.current.style.height = `${availableHeight > 200 ? availableHeight : 200}px`;
        }
      }
    };

    // Initial adjustment
    adjustTextareaHeight();

    // Adjust on window resize
    window.addEventListener("resize", adjustTextareaHeight);
    return () => {
      window.removeEventListener("resize", adjustTextareaHeight);
    };
  }, [selectedRowKeys]);

  const { request: getAllMessageByAgency, response: messageByAgencyResponse } =
    useApi({
      request: MessageRepository.getMessagesByAgency,
    });

  const { request: getAllMessagesByAdmin, response: messageByAdminResponse } =
    useApi({
      request: MessageRepository.getMessagesByAdmin,
    });

  const { request: getLogsByEnquiryId, response: enquiryLogsResponse } = useApi(
    {
      request: EnquiryEntriesRepository.getLogsById,
    }
  );

  useEffect(() => {
    if (selectedRowKeys?.length > 0) {
      setIsOpen(true);
    }
  }, [selectedRowKeys]);

  const selectedData = tableData.filter((item) =>
    selectedRowKeys.includes(item.id)
  );
  function handleSendMessage() {
    console.log(selectedData);
  }

  useEffect(() => {
    getAgencySetting();
    if (profile?.role === Roles.ADMIN) {
      if (typeof getAllMessagesByAdmin === "function") {
        getAllMessagesByAdmin({});
      }
    } else {
      if (typeof getAllMessageByAgency === "function") {
        getAllMessageByAgency({});
      }
    }
  }, [
    getAgencySetting,
    getAllMessageByAgency,
    getAllMessagesByAdmin,
    getLogsByEnquiryId,
    profile?.role,
  ]);

  useEffect(() => {
    const temp =
      profile?.role === Roles.ADMIN
        ? messageByAdminResponse?.data?.items
        : messageByAgencyResponse?.data?.items;
    const Options = (Array.isArray(temp) ? temp : [])?.map((item) => ({
      label: item.subject,
      value: item.id,
      content: item.content,
      isAdmin: item.isAdmin,
    }));
    setMessageSelected(Options?.[0]);
    setValueMesagge(Options?.[0]?.value || "");
    setOptionsMessage(Options);
  }, [
    messageByAdminResponse?.data?.items,
    messageByAgencyResponse?.data?.items,
    profile?.role,
  ]);

  useEffect(() => {
    if (optionsMessage) {
      const findOption: TSelectOptionMessage | undefined = optionsMessage.find(
        (item) => item.value === valueMessage
      );
      // @ts-ignore
      setMessageSelected(findOption);
    }
  }, [optionsMessage, valueMessage]);

  const OpenContact = (phone: string | undefined, content: string) => {
    const encodedMessage = encodeURIComponent(content);
    window.open(
      `https://api.whatsapp.com/send/?phone=${phone}&text=${encodedMessage}&type=phone_number&app_absent=0`,
      "_blank"
    );
  };

  const [content, setContent] = useState<string>(
    messageSelected?.content || ""
  ); // Ensure type is string

  useEffect(() => {
    setContent(messageSelected?.content || "");
  }, [messageSelected]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = event.target.value;
    setContent(newContent);
  };

  return (
    <div className="mb-4">
      <div className="relative z-50 mb-4 flex flex-wrap items-center justify-between gap-2.5 @container "></div>
      <div className="flex items-center mb-5 gap-4">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          All Leads
        </Title>
        {(multipleContact || profile?.role === Roles.ADMIN) &&
          profile?.role !== Roles.USER && (
            <button
              className="flex gap-2 items-center"
              onClick={() => setIsOpen(!isOpen)}
            >
              <ActionIcon size="sm" rounded="lg">
                <PiWhatsappLogoLight className="w-5 h-5" />
              </ActionIcon>
              <p className="text-base font-semibold underline">
                Multi Follow-Up
              </p>
            </button>
          )}
      </div>
      <div
        className={cn(
          "transition-all duration-300",
          isOpen ? "block opacity-1" : "hidden opacity-0"
        )}
      >
        {(multipleContact || profile?.role === Roles.ADMIN) &&
          profile?.role !== Roles.USER && (
            <div className="flex pb-5 flex-col gap-4">
              <div>
                <Title as="h5" className="flex gap-2">
                  Instructions <PiBookOpenUser size={24} />
                </Title>
                <Text as="span">
                  Check the Lead to Send a Message from the Leads Table.
                </Text>
                {/* <Stepper
              titleClassName="text-sm"
              currentIndex={3}
              className=" my-3 w-10/12"
            >
              <Stepper.Step
                variant="outline"
                title="Select lead"
                icon={<PiUserCirclePlusFill className="h-5 w-5" />}
              />
              <Stepper.Step
                variant="outline"
                title="Select template"
                icon={<CgTemplate className="h-5 w-5" />}
              />
              <Stepper.Step
                variant="outline"
                title="Send message"
                icon={<FaSquareWhatsapp className="h-5 w-5" />}
              />
              <Stepper.Step
                variant="outline"
                title="Done"
                icon={<IoCheckmarkDoneCircle className="h-5 w-5" />}
              />
            </Stepper> */}
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-5 pb-7 border-b lg:gap-x-8 lg:gap-y-12 gap-x-0 gap-y-12">
                <div
                  className="flex flex-col lg:order-2 order-1 col-span-1 lg:col-span-3 gap-6"
                  ref={formWrapperRef}
                >
                  <Title as="h5" className="flex gap-2">
                    Recipients <LuUsers size={24} />
                  </Title>
                  {findItemsById(tableData, selectedRowKeys)?.length === 0 && (
                    <Empty
                      text="No selected items"
                      textClassName="mt-2 text-disabled opacity-50"
                      alignment="center"
                      defaultImageClassName="flex justify-center h-20"
                      imageClassName="flex justify-center"
                      className="flex justify-center"
                    />
                  )}

                  {findItemsById(tableData, selectedRowKeys)?.map((data) => (
                    <WhatsAppSender
                      key={data.id}
                      templateContent={content}
                      name={data?.name}
                      email={data?.email}
                      phone={formatPhone(data?.phone, "+65")}
                      agent={data?.user as IUserData}
                      project={data?.project as IProject}
                      onRemove={() => handleRowSelect(data.id)}
                    />
                  ))}
                </div>
                <div className="col-span-2 lg:order-2 order-1">
                  <Title as="h5" className="flex gap-2">
                    Message Template <MdOutlineClearAll size={24} />
                  </Title>
                  <div className="">
                    {optionsMessage.length > 0 && (
                      <Select
                        options={optionsMessage}
                        value={valueMessage}
                        onChange={setValueMesagge}
                        label="Layout"
                        className="mb-3"
                        getOptionValue={(option: TSelectOptionMessage) =>
                          option.value
                        }
                        displayValue={(selected: string) => {
                          const findOption: TSelectOptionMessage | undefined =
                            optionsMessage?.find(
                              (option) => option.value === selected
                            );
                          // @ts-ignore
                          setMessageSelected(findOption);
                          // @ts-ignore
                          return findOption.label;
                        }}
                        getOptionDisplayValue={(option) => {
                          return <OptionItemLayout option={option} />;
                        }}
                        dropdownClassName={"z-modal [&_li>div]:w-full"}
                      />
                    )}

                    <div className={"col-span-full"}>
                      <Textarea
                        ref={templateRef}
                        label="Content"
                        placeholder="Content Message"
                        value={content}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>

      <FilterElement
        isFiltered={isFiltered}
        filters={filters}
        updateFilter={updateFilter}
        handleReset={handleReset}
        onSearch={handleSearch}
        handleSort={handleSort}
        searchTerm={searchTerm}
        onSendMessage={handleSendMessage}
      />
      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        // onRow={(record,) => ({
        //   onClick: (e) => {
        //     e.stopPropagation();
        //     e.preventDefault();
        //     openModal({
        //       view: (
        //         <EnquiryEntryDetail
        //           data={record as IEnquiryEntry}
        //           isModalView
        //         />
        //       ),
        //       customSize: "1000px",
        //     });
        //   },
        // })}
        // @ts-ignore
        columns={columns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        rowClassName="cursor-pointer"
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
}
