export const routes = {
  dashboard: "/ecommerce",
  eCommerce: {
    dashboard: "/ecommerce",
    products: "/ecommerce/products",
    createProduct: "/ecommerce/products/create",
    productDetails: (slug: string) => `/ecommerce/products/${slug}`,
    ediProduct: (slug: string) => `/ecommerce/products/${slug}/edit`,
    categories: "/ecommerce/categories",
    createCategory: "/ecommerce/categories/create",
    editCategory: (id: string) => `/ecommerce/categories/${id}/edit`,
    orders: "/ecommerce/orders",
    createOrder: "/ecommerce/orders/create",
    orderDetails: (id: string) => `/ecommerce/orders/${id}`,
    editOrder: (id: string) => `/ecommerce/orders/${id}/edit`,
    reviews: "/ecommerce/reviews",
    shop: "/ecommerce/shop",
    cart: "/ecommerce/cart",
    checkout: "/ecommerce/checkout",
    trackingId: (id: string) => `/ecommerce/tracking/${id}`,
  },
  searchAndFilter: {
    realEstate: "/search/real-estate",
    nft: "/search/nft",
    flight: "/search/flight",
  },
  support: {
    dashboard: "/support",
    inbox: "/support/inbox",
    supportCategory: (category: string) => `/support/inbox/${category}`,
    messageDetails: (id: string) => `/support/inbox/${id}`,
    snippets: "/support/snippets",
    createSnippet: "/support/snippets/create",
    viewSnippet: (id: string) => `/support/snippets/${id}`,
    editSnippet: (id: string) => `/support/snippets/${id}/edit`,
    templates: "/support/templates",
    createTemplate: "/support/templates/create",
    viewTemplate: (id: string) => `/support/templates/${id}`,
    editTemplate: (id: string) => `/support/templates/${id}/edit`,
  },
  logistics: {
    dashboard: "/logistics",
    shipmentList: "/logistics/shipments",
    customerProfile: "/logistics/customer-profile",
    createShipment: "/logistics/shipments/create",
    editShipment: (id: string) => `/logistics/shipments/${id}/edit`,
    shipmentDetails: (id: string) => `/logistics/shipments/${id}`,
    tracking: (id: string) => `/logistics/tracking/${id}`,
  },
  appointment: {
    dashboard: "/appointment",
    appointmentList: "/appointment/list",
  },
  executive: {
    dashboard: "/executive",
  },
  jobBoard: {
    dashboard: "/job-board",
  },
  analytics: "/analytics",
  financial: {
    dashboard: "/financial",
  },
  file: {
    dashboard: "/file",
    manager: "/file-manager",
    upload: "/file-manager/upload",
    create: "/file-manager/create",
  },
  pos: {
    index: "/point-of-sale",
  },
  eventCalendar: "/event-calendar",
  rolesPermissions: "/roles-permissions",
  invoice: {
    home: "/invoice",
    create: "/invoice/create",
    details: (id: string) => `/invoice/${id}`,
    edit: (id: string) => `/invoice/${id}/edit`,
    builder: "/invoice/builder",
  },
  widgets: {
    cards: "/widgets/cards",
    icons: "/widgets/icons",
    charts: "/widgets/charts",
    maps: "/widgets/maps",
    banners: "/widgets/banners",
  },
  tables: {
    basic: "/tables/basic",
    collapsible: "/tables/collapsible",
    enhanced: "/tables/enhanced",
    pagination: "/tables/pagination",
    search: "/tables/search",
    stickyHeader: "/tables/sticky-header",
    tanTable: "/tables/tan-table",
    tanTableResizable: "/tables/tan-table-resizable",
    tanTableDnD: "/tables/tan-table-dnd",
    tanTablePinning: "/tables/tan-table-pinning",
    tanTableEnhanced: "/tables/tan-table-enhanced",
    tanTableCollapsible: "/tables/tan-table-collapsible",
  },
  multiStep: "/multi-step",
  forms: {
    profileSettings: "/forms/profile-settings",
    notificationPreference: "/forms/profile-settings/notification",
    personalInformation: "/forms/profile-settings/profile",
    newsletter: "/forms/newsletter",
  },
  emailTemplates: "/email-templates",
  privacyPolicy: "/privacy-policy",
  profile: "/profile",
  welcome: "/welcome",
  comingSoon: "/coming-soon",
  accessDenied: "/access-denied",
  notFound: "/not-found",
  maintenance: "/maintenance",
  blank: "/blank",

  // Users
  user: {
    listing: "/users",
    create: "/user/create",
    edit: (id: string) => `/user/edit/${id}`,
    advanceFeature: (id: string) => `/user/advance-features/${id}`,
    loginActivity: (id: string) => `/user/login-activity/${id}`,
  },

  // Robin
  robin: {
    listing: "/round-robin",
  },

  // Team
  team: {
    listing: "/team",
  },

  // Project
  project: {
    listing: "/projects",
    create: "/project/create",
    edit: (id: string = ":id") => `/project/${id}/edit`,
    details: (id: string) => `/project/${id}`,
    agentListing: "/agent-projects",
  },
  //Location
  location: {
    listing: "/locations",
    create: "/location/create",
    edit: (id: string = ":id") => `/location/${id}/edit`,
  },

  agentLocation: {
    listing: "/agent-location",
    projects: `/agent-location/projects`,
  },
  // Enquiry Entries
  contacts: {
    listing: "/enquiry-entries",
  },

  auth: {
    // sign in
    signIn: "/sign-in",
    // forgot password
    forgotPassword: "/forgot-password",
    // verify otp
    verifyOtp: "/verify-otp",
  },

  account: {
    profile: "/account/profile",
  },

  setting: {
    agency: "/agency-setting",
    locaion: "/location-setting",
  },

  // unit type
  unitType: {
    listing: "/unit-types",
    create: "/unit-types/create",
    edit: (id: string = ":id") => `/unit-types/${id}/edit`,
  },

  // project category
  projectCategory: {
    listing: "/project-category",
    create: "/project-category/create",
    edit: (id: string = ":id") => `/project-category/${id}/edit`,
  },

  // developer
  developer: {
    listing: "/developer",
    create: "/developer/create",
    edit: (id: string = ":id") => `/developer/${id}/edit`,
  },

  // developer
  promotionBanner: {
    listing: "/promotion-banner",
    create: "/promotion-banner/create",
    edit: (id: string = ":id") => `/promotion-banner/${id}/edit`,
  },

  // highlight sections
  highlightSection: {
    lastestPromotion: "/lasted-promotion",
  },

  // unit transaction
  unitTransaction: {
    listing: (projectId: string = ":id") =>
      `/project/${projectId}/unit-transactions`,
  },

  //user preference
  userPreference: {
    listing: `/user-preferences`,
  },
  amenity: {
    listing: (projectId: string = ":id") => `/project/${projectId}/amenities`,
  },

  floorPlan: {
    listing: (projectId: string = ":id") => `/project/${projectId}/floor-plan`,
  },

  landingPages: {
    listing: `/landing-pages`,
  },

  projectLandingPages: {
    listing: (projectId: string = ":id") =>
      `/project/${projectId}/landing-pages`,
  },

  unit: {
    listing: (
      projectId: string = ":projectId",
      floorPlanId: string = ":floorPlanId"
    ) => `/project/${projectId}/floor-plan/${floorPlanId}/units`,
  },
  virtual: {
    listing: (projectId: string = ":id") =>
      `/project/${projectId}/virtual-projects`,
  },

  siteContent: {
    listing: `/site-content`,
    create: "/site-content/create",
    edit: (id: string = ":id") => `/site-content/${id}/edit`,
  },
  //Whatsapp
  whatsapp: {
    listing: `/whatsapp`,
  },

  //Mortgage
  mortgage: {
    listing: `/mortgage`,
  },

  importData: {
    listing: "/import-data",
  },
  disclaimer: {
    listing: `/disclaimer`,
  },
  layout: {
    listing: "/home-layout",
  },
  followUp: {
    listing: "/follow-up",
  },
  webhook: "/webhook",

  emailTemplate: `/email-template`,
  advanceFeatures: `/advance-features`,
  loginActivity: `/login-activity`,
};
