import { useModal } from "@/app/shared/modal-views/use-modal";
import { COUNTRY_SG_CODE, DATE_FORMAT } from "@/config/constants";
import { formatDate } from "@/utils/format-date";
import {
  PiBuildingLight,
  PiCalendarLight,
  PiMailboxLight,
  PiWarningLight,
  PiPhoneLight,
  PiUserCircleLight,
  PiXBold,
  PiAddressBookLight,
  PiFingerprintLight,
  PiPowerLight,
  PiBrowser,
  PiConfettiLight,
  PiMapPinAreaDuotone,
  PiHouseLineDuotone,
} from "react-icons/pi";
import { FaRegGrinStars } from "react-icons/fa";
import {
  ActionIcon,
  Button,
  Select,
  SelectOption,
  Textarea,
  Title,
} from "rizzui";
import { useEffect, useMemo, useState } from "react";
import { TAgencySetting } from "@/utilities/types/Setting";
import { IResponseData } from "@/utilities/types/requests";
import SettingsRepository from "@/utilities/repositories/Settings";
import useApi from "@/hooks/useApi";
import Timeline from "@/components/timeline";
import isValidJson from "@/utils/is-valid-json.ts";
import { ITrackingTimeline } from "@/utilities/types/TrackingTimeline.ts";
import MessageRepository from "@/utilities/repositories/Message.ts";
import OptionItemLayout from "@/app/app-shared/homepage-layout/forms/OptionItemLayout.tsx";
import { FaWhatsapp } from "react-icons/fa6";
import useProfile from "@/hooks/use-profile";
import { Roles } from "@/utilities/types/Users";
import cn from "@/utils/class-names";
import { createWhatsappMessage } from "@/utils/string";
import dayjs from "dayjs";
import { BiDevices, BiFlag } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";
import ChartPie from "@/components/chart-pie";
import EnquiryEntriesRepository from "@/utilities/repositories/EnquiryEntries";
import { IEnquiryEntry } from "@/utilities/types/EnquiryEntry";
import { leadScore } from "@/utilities/functions/lead";
import ScoreDetails from "@/components/score-details";
import { CgRename } from "react-icons/cg";
import { LuShoppingCart } from "react-icons/lu";
import { GoPin } from "react-icons/go";
import formatDateTime from "@/utilities/functions/formatDateTime";
import { IDistributedRoundRobin } from "@/utilities/types/DistributedRoundRobin";

interface TSelectMessage {
  label: string;
  value: string;
  content: string;
  isAdmin: boolean;
}

interface TSelectOptionMessage {
  label: string;
  value: string;
}
export default function EnquiryEntryDetail({
  data,
  isModalView,
}: {
  data?: IEnquiryEntry | IDistributedRoundRobin;
  isModalView?: boolean;
}) {
  const { closeModal } = useModal();
  const { request: getAgencySetting, response: agencySettingData } = useApi<
    IResponseData<TAgencySetting>
  >({
    request: SettingsRepository.getAgencySetting,
  });

  const [messageSelected, setMessageSelected] = useState<TSelectMessage>();
  const [valueMessage, setValueMesagge] = useState<string>();
  const { profile } = useProfile();
  const [optionsMessage, setOptionsMessage] = useState<TSelectOptionMessage[]>(
    []
  );

  const { parsedInterestedIn, parsedUnitTypes } = useMemo(() => {
    try {
      const parsedInterestedIn: string[] = JSON.parse(data?.interestedIn || "");
      const parsedUnitTypes: string[] = JSON.parse(data?.unitTypes || "");
      return { parsedInterestedIn, parsedUnitTypes };
    } catch (e) {
      return { parsedInterestedIn: [], parsedUnitTypes: [] };
    }
  }, [data?.interestedIn, data?.unitTypes]);

  const { request: getAllMessageByAgency, response: messageByAgencyResponse } =
    useApi({
      request: MessageRepository.getMessagesByAgency,
    });

  const { request: getAllMessagesByAdmin, response: messageByAdminResponse } =
    useApi({
      request: MessageRepository.getMessagesByAdmin,
    });

  const { request: getLogsByEnquiryId, response: enquiryLogsResponse } = useApi(
    {
      request: EnquiryEntriesRepository.getLogsById,
    }
  );

  useEffect(() => {
    getAgencySetting();
    if (profile?.role === Roles.ADMIN) {
      if (typeof getAllMessagesByAdmin === "function") {
        getAllMessagesByAdmin({});
      }
    } else {
      if (typeof getAllMessageByAgency === "function") {
        getAllMessageByAgency({});
      }
    }

    if (data?.id && typeof getLogsByEnquiryId === "function") {
      getLogsByEnquiryId(data.id);
    }
  }, [
    data?.id,
    getAgencySetting,
    getAllMessageByAgency,
    getAllMessagesByAdmin,
    getLogsByEnquiryId,
    profile?.role,
  ]);

  useEffect(() => {
    const temp =
      profile?.role === Roles.ADMIN
        ? messageByAdminResponse?.data?.items
        : messageByAgencyResponse?.data?.items;
    const Options = (Array.isArray(temp) ? temp : [])?.map((item) => ({
      label: item.subject,
      value: item.id,
      content: item.content,
      isAdmin: item.isAdmin,
    }));
    setMessageSelected(Options?.[0]);
    setValueMesagge(Options?.[0]?.value || "");
    setOptionsMessage(Options);
  }, [
    messageByAdminResponse?.data?.items,
    messageByAgencyResponse?.data?.items,
    profile?.role,
  ]);

  useEffect(() => {
    if (optionsMessage) {
      const findOption: TSelectOptionMessage | undefined = optionsMessage.find(
        (item) => item.value === valueMessage
      );
      // @ts-ignore
      setMessageSelected(findOption);
    }
  }, [optionsMessage, valueMessage]);

  const OpenContact = (phone: string | undefined, content: string) => {
    const encodedMessage = encodeURIComponent(content);
    window.open(
      `https://api.whatsapp.com/send/?phone=${phone}&text=${encodedMessage}&type=phone_number&app_absent=0`,
      "_blank"
    );
  };

  const timelineData =
    data?.tracking && isValidJson(data?.tracking)
      ? JSON.parse(data?.tracking)
      : ({} as ITrackingTimeline);

  const parsedMessage = createWhatsappMessage(messageSelected?.content || "", {
    "Recipient's Name": data?.name || "",
    customer_name: data?.name || "",
    customer_email: data?.email || "",
    customer_phone: data?.phone || "",
    agent_name: `${data?.user?.firstName ? `${data?.user?.firstName} ` : ""}${data?.user?.lastName || ""}`,
    agent_email: data?.user?.email || "",
    project_name:
      typeof data?.project?.name === "object"
        ? data?.project?.name?.en
        : data?.project?.name || "",
    project_address:
      typeof data?.project?.address === "object"
        ? data?.project?.address?.en
        : data?.project?.address || "",
    project_area: data?.project?.area || "",
    project_tenure: data?.project?.tenure || "",
    project_tenure_effect_from:
      data?.project?.tenureEffectFrom &&
      dayjs(data?.project?.tenureEffectFrom).isValid()
        ? dayjs(data?.project?.tenureEffectFrom).format("DD MMM YYYY")
        : "",
    appointment_date_time:
      formatDateTime(data?.appointment?.toString() || "") || "N/A",
  });

  let convertPhone = data?.phone;
  const subString = " ";
  if (convertPhone) {
    !convertPhone.includes(COUNTRY_SG_CODE) &&
      (convertPhone = COUNTRY_SG_CODE + convertPhone);
    convertPhone =
      convertPhone.substring(0, 3) + subString + convertPhone.substring(3);
    convertPhone =
      convertPhone.substring(0, 8) + subString + convertPhone.substring(8);
  } else {
    convertPhone = "N/A";
  }

  return (
    <div className="block">
      <div className="flex items-center justify-between border-b border-gray-200 p-5 md:p-7">
        <Title
          as="h3"
          className="font-lexend text-xl font-semibold md:text-2xl"
        >
          Lead Details
        </Title>
        {isModalView && (
          <ActionIcon
            size="sm"
            variant="text"
            onClick={() => closeModal()}
            className="p-0 text-gray-500 hover:!text-gray-900"
          >
            <PiXBold className="h-5 w-5" />
          </ActionIcon>
        )}
      </div>
      <div className="p-5 md:px-7 md:pb-7 items-center justify-center md:pt-6 grid grid-cols-1 lg:grid-cols-3 gap-y-4 gap-x-6">
        <div className="relative h-[250px] col-span-1 flex items-center justify-center w-full after:absolute after:inset-1/2 after:h-full after:w-full after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full @sm:py-3 !py-0">
          <ChartPie totalInitial={data?.score || 0} />
        </div>
        <div className="grid grid-cols-1 lg:col-span-2 col-span-1 gap-4 gap-x-6 @[24rem]:grid-cols-2 @[28rem]:mx-auto">
          <div
            className="rounded-lg border min-h-[254px]"
            color={
              leadScore(enquiryLogsResponse?.data?.score || 0)?.rizzuiColor
            }
            style={{
              borderColor: leadScore(enquiryLogsResponse?.data?.score || 0)
                ?.color,
            }}
          >
            <ScoreDetails enquiryLogsResponse={enquiryLogsResponse} />
          </div>
        </div>
        <div className="border border-gray-200 rounded-lg p-4 col-span-full">
          <Title as="h3" className="mb-5 font-lexend  font-medium text-lg">
            How was this lead acquired?
          </Title>
          <Timeline timelineData={timelineData} />
        </div>
        <div className="border border-gray-200 rounded-lg p-4 col-span-full">
          <Title as="h3" className="mb-5 font-lexend  font-medium text-lg">
            Summary
          </Title>
          <ul className="space-y-4 text-xs sm:text-sm">
            <li className="flex flex-start md:items-center">
              <PiCalendarLight className="me-2  w-5 shrink-0 text-xl" />
              <div className="flex flex-wrap md:flex-nowrap items-start gap-1 md:items-center">
                Preferred Appointment Date & Time:
                <span className="font-medium text-gray-1000">
                  {dayjs(data?.appointment).format("MMMM DD, YYYY hh:mm A")}
                </span>
              </div>
            </li>
            <li className="flex flex-start">
              <PiBuildingLight className="me-2 w-5 shrink-0 text-xl " />
              <div className="flex flex-wrap items-start gap-1 md:items-center">
                <span className="whitespace-nowrap">Unit types selected:</span>

                <span className="font-medium text-gray-1000">
                  {(Array.isArray(parsedUnitTypes) &&
                    parsedUnitTypes?.join(", ")) ||
                    "N/A"}
                </span>
              </div>
            </li>
            <li className="flex flex-start md:items-center">
              <FaRegGrinStars className="me-2 w-5 shrink-0 text-xl text-gray-400" />
              <div className="flex flex-wrap md:flex-nowrap items-start gap-1 md:items-center">
                <span className="whitespace-nowrap"> Interested in:</span>

                <span className="font-medium text-gray-1000">
                  {(Array?.isArray(parsedInterestedIn) &&
                    parsedInterestedIn?.join(", ")) ||
                    "N/A"}
                </span>
              </div>
            </li>
            <li className="flex items-center">
              <div className="flex items-baseline">
                <BiFlag className="me-2  self-center  w-5 shrink-0 text-xl text-gray-400" />

                <span className="whitespace-nowrap">Country (Region):</span>
              </div>

              <span className="ps-2 font-medium text-gray-1000">
                {data?.country || "N/A"}
              </span>
            </li>{" "}
            <li className="flex items-center">
              <div className="flex items-baseline">
                <IoLocationOutline className="me-2  self-center  w-5 shrink-0 text-xl text-gray-400" />
                <span className="whitespace-nowrap">IP Address:</span>
              </div>

              <span className="ps-2 font-medium text-gray-1000">
                {data?.ip || "N/A"}
              </span>
            </li>
            <li className="flex items-center">
              <div className="flex items-baseline">
                <PiBrowser className="me-2  self-center  w-5 shrink-0 text-xl text-gray-400" />

                <span className="whitespace-nowrap">Browser Type:</span>
              </div>

              <span className="ps-2 font-medium text-gray-1000">
                {data?.browser || "N/A"}
              </span>
            </li>
            <li className="flex items-center">
              <div className="flex items-baseline">
                <BiDevices className="me-2  self-center w-5 shrink-0 text-xl text-gray-400" />

                <span className="whitespace-nowrap">Device Type:</span>
              </div>

              <span className="ps-2 font-medium text-gray-1000">
                {data?.device?.model && data?.device?.vendor
                  ? `${data?.device?.model} | ${data?.device?.vendor}`
                  : "N/A"}
              </span>
            </li>
          </ul>
        </div>

        <div className="border border-gray-200 rounded-lg p-4 col-span-full">
          <div className={"grid grid-cols-2 gap-8"}>
            <div className={"col-span-2 lg:col-span-1"}>
              <Title
                as="h3"
                className="mb-5 font-lexend flex items-center font-medium text-lg"
              >
                Project
              </Title>
              <ul className="space-y-4 text-xs sm:text-sm">
                <li className="flex items-center">
                  <CgRename className="me-2  self-center  w-5 shrink-0 text-xl text-gray-400" />
                  Name:
                  <span className="ps-2 font-medium text-gray-1000">
                    {(typeof data?.project?.name === "object"
                      ? data?.project?.name?.en
                      : data?.project?.name) || "N/A"}
                  </span>
                </li>

                <li className="flex items-center">
                  <PiMapPinAreaDuotone className="me-2  self-center  w-5 shrink-0 text-xl text-gray-400" />
                  Address:
                  <span className="ps-2 font-medium text-gray-1000">
                    {data?.project?.address?.en || "N/A"}
                  </span>
                </li>
                <li className="flex items-center">
                  <PiHouseLineDuotone className="me-2  self-center  w-5 shrink-0 text-xl text-gray-400" />
                  Area:
                  <span className="ps-2 font-medium text-gray-1000">
                    {data?.project?.area || "N/A"}
                  </span>
                </li>
                <li className="flex items-center">
                  <LuShoppingCart className="me-2  self-center  w-5 shrink-0 text-xl text-gray-400" />
                  Tenure:
                  <span className="ps-2 font-medium text-gray-1000">
                    {data?.project?.tenure || "N/A"}
                  </span>
                </li>
                <li className="flex items-center">
                  <GoPin className="me-2  self-center  w-5 shrink-0 text-xl text-gray-400" />
                  Tenure effect from:
                  <span className="ps-2 font-medium text-gray-1000">
                    {data?.project?.tenureEffectFrom &&
                    dayjs(data?.project?.tenureEffectFrom).isValid()
                      ? dayjs(data?.project?.tenureEffectFrom).format(
                          "DD MMM YYYY"
                        )
                      : "N/A"}
                  </span>
                </li>
              </ul>
            </div>
            <div className={"col-span-2 lg:col-span-1"}>
              <div>
                <Button
                  className={"mb-3 w-full h-fit"}
                  onClick={() => {
                    OpenContact(
                      data?.phone?.startsWith("0")
                        ? `+65${data?.phone?.slice(1) || ""}`
                        : data?.phone || "",
                      parsedMessage
                    );
                  }}
                >
                  <span className={"flex gap-2 items-center"}>
                    <FaWhatsapp /> Contact {convertPhone} on Whatsapp
                  </span>
                </Button>
              </div>
              {optionsMessage.length > 0 && (
                <Select
                  options={optionsMessage}
                  value={valueMessage}
                  onChange={setValueMesagge}
                  label="Layout"
                  className="mb-3"
                  getOptionValue={(option: SelectOption) => option.value}
                  displayValue={(selected: string) => {
                    const findOption: TSelectOptionMessage | undefined =
                      optionsMessage?.find(
                        (option) => option.value === selected
                      );
                    // @ts-ignore
                    setMessageSelected(findOption);
                    // @ts-ignore
                    return findOption.label;
                  }}
                  getOptionDisplayValue={(option) => {
                    return <OptionItemLayout option={option} />;
                  }}
                  dropdownClassName={"z-modal [&_li>div]:w-full"}
                />
              )}

              <div className={"col-span-full"}>
                <Textarea
                  label="Content"
                  placeholder="Content Message"
                  value={parsedMessage}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={cn(
            "border border-gray-200 rounded-lg p-4",
            "col-span-full lg:col-span-1"
          )}
        >
          <Title as="h3" className="mb-5 font-lexend  font-medium text-lg">
            Customer
          </Title>

          <ul className="space-y-4 text-xs sm:text-sm">
            <li className="flex items-center flex-wrap h-full gap-5">
              <div className="flex items-center">
                <PiUserCircleLight className="me-2  w-5 shrink-0 text-xl" />
                Name:
                <span className="ps-2 font-medium text-gray-1000">
                  {data?.name || "N/A"}
                </span>
              </div>
              <div className="flex items-center">
                <PiConfettiLight className="me-2  w-5 shrink-0 text-xl" />
                Score:
                <span
                  className="ps-2 flex items-center justify-center font-medium text-gray-1000"
                  style={{ color: leadScore(data?.score || 0)?.color }}
                >
                  {data?.score || 0}
                  {data?.score === 0 && <PiWarningLight className="h-4 w-4" />}
                </span>
              </div>
            </li>
            <li className="flex items-center">
              <PiPhoneLight className="me-2  w-5 shrink-0 text-xl" />
              Phone:
              <span className="ps-2 font-medium text-gray-1000">
                {convertPhone}
              </span>
            </li>
            <li className="flex items-start">
              <PiMailboxLight className="me-2  w-5 shrink-0 text-xl" />
              <div className="flex flex-wrap items-start gap-1 md:items-center">
                Email:
                <span className="font-medium text-gray-1000">
                  {data?.email || "N/A"}
                </span>
              </div>
            </li>
          </ul>
        </div>

        {profile?.role === Roles.ADMIN && (
          <div className="border border-gray-200 rounded-lg p-4 h-full col-span-full lg:col-span-1">
            <Title as="h3" className="mb-5 font-lexend  font-medium text-lg">
              Agent
            </Title>

            <ul className="space-y-4 text-xs sm:text-sm">
              <li className="flex items-center">
                <PiUserCircleLight className="me-2  w-5 shrink-0 text-xl" />
                Name:
                <span className="ps-2 font-medium text-gray-1000">
                  {data?.user.firstName + " " + data?.user.lastName || "N/A"}
                </span>
              </li>

              <li className="flex items-center">
                <PiMailboxLight className="me-2  w-5 shrink-0 text-xl" />
                Email:
                <span className="ps-2 font-medium text-gray-1000 break-words whitespace-pre-wrap">
                  {data?.user?.email || "N/A"}
                </span>
              </li>
            </ul>
          </div>
        )}

        <div
          className={cn(
            "border border-gray-200 h-full rounded-lg p-4",
            "col-span-full lg:odd:col-span-2 lg:even:col-span-1"
          )}
        >
          <Title as="h3" className="mb-5 font-lexend  font-medium text-lg">
            CEA
          </Title>

          <ul className="space-y-4 text-xs sm:text-sm">
            <li className="flex items-center">
              <PiAddressBookLight className="me-2  w-5 shrink-0 text-xl" />
              CEA Name:
              <span className="ps-2 font-medium text-gray-1000">
                {data?.ceaInfo?.name || "N/A"}
              </span>
            </li>
            <li className="flex items-center">
              <PiPowerLight className="me-2  w-5 shrink-0 text-xl" />
              CEA Reg No:
              <span className="ps-2 font-medium text-gray-1000">
                {data?.ceaInfo?.registrationNumber || "N/A"}
              </span>
            </li>
            <li className="flex md:items-center items-start">
              <PiFingerprintLight className="me-2 self-start w-5 shrink-0 text-xl" />
              <div className="flex flex-wrap items-start gap-1 md:items-center">
                <span className="whitespace-nowrap">CEA Agency:</span>
                <span className="font-medium whitespace-nowrap text-gray-1000">
                  {data?.ceaInfo?.currentEa || "N/A"}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
