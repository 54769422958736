"use client";

import DeletePopover from "@/app/shared/delete-popover";
import { HeaderCell } from "@/components/ui/table";
import { IDeveloper } from "@/utilities/types/Developer";
import { ISchedule } from "@/utilities/types/RoundRobin";
import { Button } from "rizzui";
type Columns = {
  data: ISchedule[];
  onDeleteItem?: (id: string) => Promise<void>;
  onEditItem?: (row: IDeveloper) => void;
  onUpdateAgents: (cellData: ISchedule) => void;
};

export const GetColumns = ({ onDeleteItem, onUpdateAgents, data }: Columns) => [
  {
    title: <HeaderCell title="Start time" align="center" />,
    key: "startTime",
    dataIndex: "startTime",

    align: "center",
    render: (data: any) => data,
  },
  {
    title: <HeaderCell title="End time" align="center" />,
    key: "endTime",
    dataIndex: "endTime",
    align: "center",
    render: (data: any) => data,
  },

  {
    title: <HeaderCell title="Selected Agents" align="center" />,
    key: "users",
    dataIndex: "users",
    align: "center",
    render: (_, data: ISchedule) => (
      <Button onClick={() => onUpdateAgents(data)}>View</Button>
    ),
  },
  {
    title: <HeaderCell title="" align="center" />,
    key: "users",
    dataIndex: "users",
    align: "center",
    render: (_, data: ISchedule) => (
      <DeletePopover
        title={`Delete developer`}
        description={`Are you sure you want to delete this developer?`}
        onDelete={async () => await onDeleteItem?.(data?.id)}
      />
    ),
  },

  // {
  //   title: (
  //     <HeaderCell
  //       className="ps-3"
  //       title={<span className="whitespace-nowrap">Actions</span>}
  //       align="center"
  //     />
  //   ),
  //   dataIndex: "action",
  //   key: "action",
  //   width: 120,
  //   render: (_: string, row: IDeveloper) => (
  //     <div className="flex items-center justify-end gap-3 pe-4">
  //       <Tooltip
  //         size="sm"
  //         content={"Edit Developer"}
  //         placement="top"
  //         color="invert"
  //       >
  //         <ActionIcon
  //           size="sm"
  //           variant="outline"
  //           onClick={() => onEditItem?.(row)}
  //         >
  //           <PencilIcon className="h-4 w-4" />
  //         </ActionIcon>
  //       </Tooltip>
  //       <DeletePopover
  //         title={`Delete developer`}
  //         description={`Are you sure you want to delete this developer?`}
  //         onDelete={async () => await onDeleteItem?.(row.id)}
  //       />
  //     </div>
  //   ),
  // },
];
