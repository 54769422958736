import React from "react";
import cn from "@/utils/class-names";
import Link from "next/link";
import { ITrackingTimeline } from "@/utilities/types/TrackingTimeline.ts";
import { formatDate } from "@/utils/format-date.ts";
import { DATE_FORMAT } from "@/config/constants.ts";
import isValidDate from "@/utils/isValidDate.ts";
import dayjs from "dayjs";

export interface TimelineProps {
  timelineData: ITrackingTimeline;
}

const Timeline: React.FC<TimelineProps> = ({ timelineData }) => {
  if (!timelineData?.timeline)
    return (
      <div>
        <span className="whitespace-nowrap font-bold block">No data</span>
      </div>
    );

  const lastTimelineEvent =
    timelineData?.timeline && timelineData?.timeline?.length > 0
      ? timelineData?.timeline[timelineData?.timeline?.length - 1]
      : null;

  const timeline = [
    {
      title: isValidDate(timelineData?.startTime)
        ? dayjs(new Date(timelineData?.startTime))
            .tz("Asia/Singapore")
            .format("MMM DD, YYYY hh:mm A")
        : "",
      name: "Start Time",
      slug: "",
      enquiry: null,
    },
    ...timelineData.timeline.map((event) => ({
      title: dayjs(new Date(event.visitedAt))
        .tz("Asia/Singapore")
        .format("MMM DD, YYYY hh:mm A"),
      name: event?.projectName,
      slug: event.projectId,
      enquiry: isValidDate(event?.submittedAt)
        ? dayjs(new Date(event.submittedAt!))
            .tz("Asia/Singapore")
            .format("MMM DD, YYYY hh:mm A")
        : null,
    })),
    {
      title:
        timelineData?.endTime && isValidDate(timelineData?.endTime)
          ? dayjs(new Date(timelineData?.endTime))
              .tz("Asia/Singapore")
              .format("MMM DD, YYYY hh:mm A")
          : "",
      name: `${lastTimelineEvent?.projectName}`,
      slug: lastTimelineEvent?.projectId,
      enquiry:
        timelineData?.endTime && isValidDate(timelineData?.endTime)
          ? dayjs(new Date(timelineData?.endTime))
              .tz("Asia/Singapore")
              .format("MMM DD, YYYY hh:mm A")
          : "",
    },
  ];

  return (
    <div className="w-full max-w-5xl mx-auto overflow-x-auto">
      <ol className="items-start sm:flex pb-3 pt-1">
        {timeline?.map((event, index) => (
          <li
            key={event?.title + index}
            className="relative mb-6 min-w-[200px] sm:mb-0"
          >
            <div className="flex items-center">
              <div className="z-10 flex items-center justify-center relative w-6 h-6">
                <div
                  className={cn(
                    "z-10 flex items-center justify-center w-6 h-6 bg-gray-900 rounded-full ring-0 dark:bg-blue-900 sm:ring-1 dark:ring-gray-900 shrink-0",
                    !!event?.enquiry && " sm:ring-4"
                  )}
                ></div>
                <div className="z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-5 h-5 bg-gray-900 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-1 dark:ring-gray-900 shrink-0"></div>
              </div>
              <div
                className={cn(
                  "hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700",
                  index === timeline?.length - 1 && "sm:hidden " + "hidden"
                )}
              ></div>
            </div>

            <div className="mt-3 sm:pe-8">
              {event?.slug && (
                <Link
                  href={`project/${event?.slug}/edit`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700"
                >
                  <span className="font-bold block break-words whitespace-pre-wrap">
                    {event?.name}
                  </span>
                </Link>
              )}
              {!event?.slug && (
                <span className="whitespace-nowrap font-bold block">
                  {event?.name}
                </span>
              )}
              {event?.enquiry && (
                <span className="block font-bold">Enquiry submitted</span>
              )}
              <span
                className="whitespace-nowrap font-medium block"
                dangerouslySetInnerHTML={{
                  __html: event?.title.replace(".", "<br>"),
                }}
              />
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Timeline;
