export default function formatPhone(
  phone: string | null | undefined,
  countryCode: string = "+65"
): string {
  if (!phone) return ""; // Return empty string if phone is null or undefined

  // Remove non-numeric characters except for "+"
  let cleanedPhone = phone.replace(/[^\d+]/g, "");

  // Ensure the phone number includes the country code
  if (!cleanedPhone.startsWith(countryCode)) {
    cleanedPhone = countryCode + cleanedPhone;
  }

  // Remove country code for formatting
  const localNumber = cleanedPhone.slice(countryCode.length);

  // Format the local number as XXXX YYYY
  const formattedLocalNumber = `${localNumber.slice(0, 4)} ${localNumber.slice(4, 8)}`;

  // Combine country code with formatted local number
  return `${countryCode} ${formattedLocalNumber.trim()}`;
}
