import { IProjectLocale } from "@/utilities/types/Project";
import { SelectedProject } from "@/utilities/types/SectionSettings";
import { useDebounceEffect } from "ahooks";
import { memo, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import {
  Checkbox,
  Input,
  MultiSelect,
  MultiSelectOption,
  Tooltip,
} from "rizzui";
import cn from "@/utils/class-names.ts";
import { useAtom } from "jotai";
import { atomHandleSelectLocation } from "../store";

export interface SelectProjectProps {
  onSearch: (search: string) => void;
  projects: IProjectLocale[];
  loading?: boolean;
  onSelect: (project: IProjectLocale, checked: boolean) => void;
  selectedProjects: SelectedProject[];
  sectionId: string;
  isSectionDefault?: boolean;
  options: MultiSelectOption[];
}

function SelectProject({
  onSearch,
  projects,
  loading,
  onSelect,
  selectedProjects,
  sectionId,
  isSectionDefault,
  options,
}: SelectProjectProps) {
  const [search, setSearch] = useState<string>("");
  useDebounceEffect(
    () => {
      onSearch(search);
    },
    [search],
    { wait: 800 }
  );

  // const [locationOptions] = useAtom(atomLocation);
  const [locationSelected, onSelectLocation] = useAtom(
    atomHandleSelectLocation
  );

  return (
    <div className="space-y-2 h-full">
      <MultiSelect
        value={locationSelected?.[sectionId] || []}
        onChange={(option: any) => {
          onSelectLocation({
            [sectionId]: option,
          });
        }}
        searchable
        searchClassName="sticky"
        options={options}
        className={"w-full"}
        optionClassName="aria-[disabled]:bg-muted/70 aria-[disabled]:cursor-not-allowed"
        displayValue={(selected, options) => {
          const selectedString = options
            .filter((item) => selected.includes(item.value))
            .map((item) => item.label);
          return (
            <p className="w-full truncate">{selectedString?.join(", ")}</p>
          );
        }}
        getOptionDisplayValue={(option, selected) => {
          const ElementWrapper = option.disabled ? Tooltip : "div";
          return (
            <ElementWrapper
              content={
                option.disabled
                  ? "This location has  selected projects"
                  : undefined
              }
            >
              <div className={cn("flex flex-row items-center w-full")}>
                <Checkbox
                  size="sm"
                  checked={selected}
                  className="pointer-events-none [&_svg]:top-0"
                />
                <span className="ml-2">{option?.label}</span>
              </div>
            </ElementWrapper>
          );
        }}
        disabled={isSectionDefault}
      />
      <Input
        type="search"
        placeholder="Search by name project"
        value={search}
        onClear={() => setSearch("")}
        onChange={(event) => setSearch(event.target.value)}
        suffix={
          loading ? (
            <LoaderIcon className="size-4" />
          ) : (
            <PiMagnifyingGlassBold className="h-4 w-4" />
          )
        }
        rounded="lg"
        clearable
        className="w-full"
      />
      <div className="list-none px-2 py-4 max-h-[400px] h-full overflow-y-auto  border-b border-slate-300 last:border-none">
        {projects.map((project) => (
          <div
            className={cn(
              `flex justify-between p-2 border-b border-slate-300 last:border-none`
            )}
            key={project.id}
          >
            <div className="flex-1 overflow-hidden">
              <p className={"text-base font-bold truncate"}>
                {typeof project?.name === "object"
                  ? project?.name?.en
                  : project?.name}
              </p>
              <p className="text-slate-500 truncate">
                {typeof project?.location?.name === "object"
                  ? project?.location?.name?.en
                  : project?.location?.name}
              </p>
            </div>

            <Checkbox
              size="sm"
              onChange={(e) => {
                onSelect(project, e.target.checked);
              }}
              checked={selectedProjects.some(
                (pj) => pj.project.id === project.id
              )}
              className="flex-shrink-0"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(SelectProject);
