"use client";

import { HeaderCell } from "@/components/ui/table";
import { Text } from "rizzui";
import formatPhone from "@/utilities/functions/format-phone";
import { IDistributedRoundRobin } from "@/utilities/types/DistributedRoundRobin";
import formatDateTime from "@/utilities/functions/formatDateTime";
import { IUserData } from "@/utilities/types/Users";
import { useModal } from "@/app/shared/modal-views/use-modal";
import EnquiryEntryDetail from "../../enquiry-entrties/details/enquiry-entries-details";

type ColumnsProps = {
  profile: IUserData | null;
};

export const GetColumns = ({ profile }: ColumnsProps) => [
  profile?.role === "admin" && {
    title: <HeaderCell title="Agent" align="left" />,
    key: "agent",
    dataIndex: "agent",
    align: "left",

    render: (_: unknown, row: IDistributedRoundRobin) => {
      return (
        <div>
          <Text className="text-sm font-medium text-gray-900 dark:text-gray-700">
            {row.user.firstName} {row.user.lastName}
          </Text>
          {row.user?.phone && (
            <Text className="text-[13px] text-gray-500">
              {formatPhone(row.user?.phone)}
            </Text>
          )}
          {row.user?.email && (
            <Text className="text-[13px] text-gray-500">{row.user?.email}</Text>
          )}
        </div>
      );
    },
  },
  {
    title: <HeaderCell title="Assigned to" align="left" />,
    key: "assignedTo",
    dataIndex: "assignedTo",
    align: "left",

    render: (_: unknown, row: IDistributedRoundRobin) => {
      // if (profile?.role == "admin") return null;
      return (
        <div>
          <Text className="text-sm font-medium text-gray-900 dark:text-gray-700">
            {row.assignedTo.firstName} {row.assignedTo.lastName}
          </Text>
          {row.assignedTo?.phone && (
            <Text className="text-[13px] text-gray-500">
              {formatPhone(row.assignedTo?.phone)}
            </Text>
          )}
          {row.assignedTo?.email && (
            <Text className="text-[13px] text-gray-500">
              {row.assignedTo?.email}
            </Text>
          )}
        </div>
      );
    },
  },
  {
    title: <HeaderCell title="Assigned at" align="left" />,
    key: "distributedAt",
    dataIndex: "distributedAt",
    align: "left",

    render: (_: unknown, row: IDistributedRoundRobin) => {
      return (
        <div>
          <Text className="text-sm font-medium text-gray-900 dark:text-gray-700">
            {formatDateTime(row.distributedAt)}
          </Text>
        </div>
      );
    },
  },
  {
    title: (
      <HeaderCell
        className="ps-3"
        title={<span className="whitespace-nowrap">Lead</span>}
        align="center"
      />
    ),
    dataIndex: "lead",
    key: "lead",
    align: "center",
    render: (_: string, row: IDistributedRoundRobin) => (
      <RenderAction row={row} />
    ),
  },
];

const RenderAction = ({ row }: { row: IDistributedRoundRobin }) => {
  const { openModal } = useModal();
  return (
    <button
      onClick={() =>
        openModal({
          view: <EnquiryEntryDetail data={row} isModalView />,
          customSize: "1000px",
          size: "xl",
        })
      }
    >
      <Text className="text-sm font-medium text-gray-900 dark:text-gray-700">
        {row?.name}
      </Text>
      {row?.phone && (
        <Text className="text-[13px] text-gray-500">
          {formatPhone(row?.phone)}
        </Text>
      )}
      {row?.email && (
        <Text className="text-[13px] text-gray-500">{row?.email}</Text>
      )}
    </button>
  );
};
