import Link from "next/link";
import {
  ElementType,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { carbonMenuItems } from "@/layouts/carbon/carbon-menu-items";
import cn from "@/utils/class-names";
import { PiCaretDownBold } from "react-icons/pi";
import Menu from "@/components/ui/menu/dropdown/menu";
import StatusBadge from "@/components/get-status-badge";
import { SortableList } from "@/components/dnd-sortable/dnd-sortable-list";
import { arrayMove } from "@dnd-kit/sortable";
import { useLocation, useNavigate } from "react-router-dom";
import useProfile from "@/hooks/use-profile";
import { useDrawer } from "@/app/shared/drawer-views/use-drawer";
import { checkToHideMenuItem } from "@/utilities/functions/menu";
import { EFeatureMode, EFeatureType } from "@/utilities/types/AdvanceFeature";
import { useAdvanceFeatures } from "@/hooks/use-advance-features";
import isEmpty from "lodash/isEmpty";
import { Roles } from "@/utilities/types/Users";
import AdvanceFeatureRepository from "@/utilities/repositories/AdvanceFeature";

export function CarbonSidebarMenu() {
  // const pathname = usePathname();
  const pathname = useLocation().pathname;

  const navigate = useNavigate();
  const [items, setItems] = useState(carbonMenuItems);
  const {
    dynamicLayoutMode,
    landingPageFeature,
    telegramFeature,
    multipleAccount,
  } = useAdvanceFeatures();

  function handleChange(event: any) {
    const { active, over } = event;
    if (!active || !over) return;
    const oldIndex = items.findIndex((item) => item.id === active.id);
    const newIndex = items.findIndex((item) => item.id === over.id);
    setItems((items) => arrayMove(items, oldIndex, newIndex));
  }

  const { profile } = useProfile();

  const enabledLandingPage = useMemo(async () => {
    if (!profile?.id) return false;
    const responseFeatures = await AdvanceFeatureRepository.getUserFeaturesById(
      profile?.id
    );
    const isLandingPage = responseFeatures?.data?.filter(
      (feature) =>
        feature?.enabled && feature?.feature?.type === EFeatureType.LandingPage
    );
    return isLandingPage?.length > 0 ? true : false;
  }, [profile?.id]);

  const updateMenu = useCallback(async () => {
    if (!carbonMenuItems) return;

    let updatedItems = carbonMenuItems;

    const isEnabledLandingPage = await enabledLandingPage;
    if (
      (isEnabledLandingPage && profile?.role !== Roles.ADMIN) ||
      profile?.role === Roles.ADMIN
    ) {
      updatedItems = carbonMenuItems;
    } else {
      updatedItems = updatedItems.filter(
        (item) => item.id !== EFeatureType.LandingPage
      );
    }

    if (!isEmpty(multipleAccount) && profile?.role !== Roles.ADMIN) {
      updatedItems = carbonMenuItems;
    } else {
      updatedItems = updatedItems.filter(
        (item) =>
          item.id !== EFeatureType.TeamAccount && item.id !== "round-robin"
      );
    }

    if (dynamicLayoutMode === EFeatureMode.Advance) {
      updatedItems = updatedItems.map((item) => {
        if (item.id === "agentLocation") {
          return { ...item, name: "My Layout", title: "My Layout" }; // Create a new object with the updated title
        } else return item;
      });
    } else {
      updatedItems = updatedItems.map((item) => {
        if (item.id === "agentLocation") {
          return { ...item, name: "All Districts", title: "All Districts" }; // Create a new object with the updated title
        } else return item;
      });
    }

    if (isEmpty(telegramFeature)) {
      updatedItems = updatedItems.filter(
        (item) => item.id !== "advanced-features"
      );
    }

    setItems([...updatedItems]);
  }, [
    dynamicLayoutMode,
    enabledLandingPage,
    multipleAccount,
    profile?.role,
    telegramFeature,
  ]);

  useEffect(() => {
    if (
      dynamicLayoutMode ||
      isEmpty(landingPageFeature) ||
      !landingPageFeature?.enabled
    )
      updateMenu();
  }, [updateMenu, dynamicLayoutMode, landingPageFeature]);

  const currentRole = profile?.role;

  const advancedFeatures = profile?.userFeatures?.filter(
    (item: any) => !!item?.enabled
  );

  const { closeDrawer } = useDrawer();

  return (
    <div className="mb-auto">
      <ul className="pb-12">
        <SortableList items={items} onChange={handleChange}>
          {items.map((item, index) => {
            const Icon = item.icon;
            if (!currentRole) return null;

            const pathnameExistInDropdowns: boolean = item?.menuItems?.some(
              (dropdownItem) =>
                dropdownItem.href === pathname ||
                dropdownItem.subMenuItems?.some(
                  (subMenuItem) => subMenuItem.href === pathname
                )
            ) as boolean;
            const isDropdownOpen = Boolean(pathnameExistInDropdowns);
            const isActive = !isDropdownOpen && pathname === item.href;

            // Permission
            const hasPermissionInChild = !!item?.menuItems?.some((item) => {
              if (!item?.subMenuItems || item.subMenuItems.length === 0) {
                return item.permissions?.includes(currentRole); // get Permission form menu items
              } else {
                return !!item?.subMenuItems?.some((subIt) => {
                  return subIt.permissions?.includes(currentRole);
                });
              }
            });

            const isShowParentTab =
              item.permissions?.includes(currentRole) || hasPermissionInChild;

            if (!isShowParentTab) return null;
            return (
              <Fragment key={"sortable-menu" + item.name + "-" + index}>
                <Menu
                  trigger="hover"
                  placement="right-start"
                  offset={2}
                  closeDelay={0}
                >
                  <Menu.Trigger>
                    <div
                      className={cn(
                        "group relative mx-3.5 flex grow cursor-pointer items-center justify-between overflow-hidden rounded-md px-3 py-2.5 font-medium transition-all hover:ps-7 lg:my-1 2xl:my-2 2xl:me-5",
                        isDropdownOpen || isActive
                          ? "bg-primary text-gray-0 pointer-events-none"
                          : "text-gray-700 transition-all duration-200 hover:bg-gray-100 dark:text-gray-700/90 dark:hover:text-gray-700"
                      )}
                      onClick={() => {
                        item?.href && navigate(item.href);
                        closeDrawer();
                      }}
                    >
                      <span className="flex items-center">
                        {Icon && (
                          <span
                            className={cn(
                              "me-2 inline-flex h-6 w-6 items-center justify-center rounded-md transition-all [&>svg]:h-[24px] [&>svg]:w-[24px]",
                              isDropdownOpen
                                ? "text-gray-0"
                                : "text-gray-400 dark:text-gray-500 dark:group-hover:text-gray-700"
                            )}
                          >
                            <Icon />
                          </span>
                        )}
                        {item.name}
                      </span>
                      {item?.menuItems && (
                        <div className="flex items-center transition-all group-hover:gap-1">
                          <PiCaretDownBold
                            strokeWidth={3}
                            className={cn(
                              "h-3.5 w-3.5 -rotate-90 transition-transform duration-200 rtl:rotate-90",
                              isDropdownOpen ? "text-gray-0" : "text-gray-900"
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </Menu.Trigger>
                  {item?.menuItems && (
                    <Menu.List className="relative w-[280px] !border-transparent  !px-2 !py-3 after:absolute after:-start-5 after:top-0 after:h-full after:w-5 dark:border-gray-300">
                      {item?.menuItems?.map((dropdownItem, index) => {
                        const isChildActive =
                          pathname === (dropdownItem?.href as string);
                        const pathnameExistInChildDropdowns: any =
                          dropdownItem?.subMenuItems?.filter(
                            (dropdownItem) => dropdownItem.href === pathname
                          );
                        const isChildDropdownActive = Boolean(
                          pathnameExistInChildDropdowns?.length
                        );
                        const DropdownIcon = dropdownItem?.icon;

                        const isHasPermissionInChild =
                          !!dropdownItem?.subMenuItems?.some((subItem) => {
                            return subItem.permissions?.includes(currentRole);
                          });

                        const isShowParentTab =
                          dropdownItem.permissions?.includes(currentRole) ||
                          isHasPermissionInChild;

                        if (!isShowParentTab) return null;
                        return (
                          <Menu.Item
                            key={"dropdown" + dropdownItem?.name + index}
                            className={cn(
                              "px-0 py-0 transition-all data-[hover=true]:dark:bg-gray-200",
                              isChildDropdownActive &&
                                "bg-gray-100 dark:bg-gray-200"
                            )}
                          >
                            {dropdownItem?.subMenuItems?.length ? (
                              <ul className="w-full">
                                <Menu
                                  trigger="hover"
                                  placement="right-start"
                                  offset={0}
                                  closeDelay={0}
                                >
                                  <Menu.Trigger>
                                    <li
                                      className={cn(
                                        "group relative flex cursor-pointer items-center justify-between rounded-md px-3.5 py-2 font-medium",
                                        isChildDropdownActive
                                          ? "before:top-2/5 rounded-md bg-gray-100 text-primary before:absolute before:start-0 before:block before:h-4/5 before:w-1 before:rounded-ee-md before:rounded-se-md before:bg-primary dark:bg-gray-200 2xl:before:start-0"
                                          : "text-gray-700 transition-all duration-200 hover:bg-gray-100 dark:text-gray-700/90 hover:dark:bg-gray-200 dark:hover:text-gray-700"
                                      )}
                                    >
                                      <span className="flex items-center">
                                        {DropdownIcon && (
                                          <span
                                            className={cn(
                                              "me-2 inline-flex h-5 w-5 items-center justify-center rounded-md [&>svg]:h-[20px] [&>svg]:w-[20px]",
                                              isChildDropdownActive
                                                ? "text-primary"
                                                : "text-gray-400 dark:text-gray-500 dark:group-hover:text-gray-700"
                                            )}
                                          >
                                            {item?.menuItems && (
                                              <DropdownIcon />
                                            )}
                                          </span>
                                        )}
                                        {dropdownItem.name}
                                      </span>

                                      <PiCaretDownBold
                                        strokeWidth={3}
                                        className={cn(
                                          "h-3.5 w-3.5 -rotate-90 transition-transform duration-200 rtl:rotate-90",
                                          isChildDropdownActive
                                            ? "text-primary"
                                            : "text-gray-900"
                                        )}
                                      />
                                    </li>
                                  </Menu.Trigger>
                                  <Menu.List className="!border-transparent dark:border-gray-300 dark:bg-gray-100">
                                    {dropdownItem?.subMenuItems?.map(
                                      (subMenuItem, index) => {
                                        const isChildActive =
                                          pathname ===
                                          (subMenuItem?.href as string);
                                        if (
                                          !subMenuItem.permissions?.includes(
                                            currentRole
                                          )
                                        )
                                          return null;
                                        return (
                                          <Menu.Item
                                            key={
                                              "sub-menu" +
                                              subMenuItem?.name +
                                              index
                                            }
                                            className="px-0 py-0"
                                          >
                                            <Link
                                              href={subMenuItem?.href}
                                              className={cn(
                                                "relative flex w-full items-center justify-between rounded-md px-3.5 py-2 font-medium capitalize text-gray-900",
                                                isChildActive
                                                  ? "text-primary"
                                                  : "text-gray-900 transition-colors duration-200 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-200"
                                              )}
                                            >
                                              <span className="flex items-center truncate">
                                                <span className="truncate">
                                                  {subMenuItem?.name}
                                                </span>
                                              </span>
                                              {subMenuItem?.badge?.length ? (
                                                <StatusBadge
                                                  status={subMenuItem?.badge!}
                                                />
                                              ) : null}
                                            </Link>
                                          </Menu.Item>
                                        );
                                      }
                                    )}
                                  </Menu.List>
                                </Menu>
                              </ul>
                            ) : (
                              <MenuLink
                                item={dropdownItem}
                                isChildActive={isChildActive}
                                isDropdownOpen={isDropdownOpen}
                              />
                            )}
                          </Menu.Item>
                        );
                      })}
                    </Menu.List>
                  )}
                </Menu>
              </Fragment>
            );
          })}
        </SortableList>
      </ul>
    </div>
  );
}

type MenuItemsProps = {
  as?: ElementType;
  item: any;
  isChildActive?: boolean;
  isDropdownOpen?: boolean;
  className?: string;
};

function MenuLink({ item, isChildActive }: MenuItemsProps) {
  const Icon = item?.icon;

  return (
    <Link
      href={item?.href}
      className={cn(
        "relative flex w-full items-center justify-between rounded-md px-3.5 py-2 font-medium capitalize text-gray-900",
        isChildActive
          ? "before:top-2/5 bg-gray-100 text-primary before:absolute before:-start-2.5 before:block before:h-4/5 before:w-1 before:rounded-ee-md before:rounded-se-md before:bg-primary dark:bg-gray-200 2xl:before:-start-2.5"
          : "text-gray-900 transition-colors duration-200 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-700/90 dark:hover:bg-gray-200"
      )}
    >
      <div className="flex items-center truncate">
        {Icon && (
          <span
            className={cn(
              "me-3 inline-flex h-5 w-5 items-center justify-center rounded-md [&>svg]:h-[20px] [&>svg]:w-[20px]",
              isChildActive
                ? "text-primary"
                : "text-gray-400 dark:text-gray-500 dark:group-hover:text-gray-700"
            )}
          >
            <Icon />
          </span>
        )}
        <span className="truncate">{item?.name}</span>
      </div>
      {item?.badge?.length ? <StatusBadge status={item?.badge} /> : null}
    </Link>
  );
}
