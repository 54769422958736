import FormGroup from "@/app/shared/form-group";
import { DatePicker } from "@/components/ui/datepicker";
import QuillEditor from "@/components/ui/quille-editor/quill-editor";
import useApi from "@/hooks/useApi";
import { marketSegmentOptions, tenureOptions } from "@/utilities/enums/Project";
import CategoryRepository from "@/utilities/repositories/Categories";
import LocationRepository from "@/utilities/repositories/Location";
import { ILocation } from "@/utilities/types/Location";
import { ICategory, IProjectDetail } from "@/utilities/types/Project";
import { IResponseData } from "@/utilities/types/requests";
import cn from "@/utils/class-names";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LoaderIcon } from "react-hot-toast";
import { Input, Select } from "rizzui";
import { IDeveloperFromList } from "@/utilities/types/Developer.ts";
import DeveloperRepository from "@/utilities/repositories/Developer.ts";
import { Roles } from "@/utilities/types/Users.ts";
import useProfile from "@/hooks/use-profile";

export interface InformationProjectPartProps {
  className?: string;
}

export default function InformationProjectPart({
  className,
}: InformationProjectPartProps) {
  const {
    request: fetchAllCategories,
    response: categories,
    loading,
  } = useApi<IResponseData<ICategory[]>>({
    request: CategoryRepository.getAllCategories,
  });

  const { request: fetchAllDeveloper, response: developers } = useApi<
    IResponseData<IDeveloperFromList[]>
  >({
    request: DeveloperRepository.getAllDevelopers,
  });

  const {
    request: fetchAllLocation,
    response: locations,
    loading: loadingLocation,
  } = useApi<IResponseData<ILocation[]>>({
    request: LocationRepository.getAllLocation,
  });

  const categoyOptions = categories?.data.map((item: ICategory) => ({
    value: item.id,
    label: item.shortname ?? item.name,
  }));

  const locationOptions = locations?.data.map((item: ILocation) => ({
    value: item.id,
    label: item.name,
  }));

  const developerOptions =
    developers?.data
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((item: IDeveloperFromList) => ({
        value: item.id,
        label: item.name,
      })) || [];
  useEffect(() => {
    Promise.all([
      fetchAllCategories(),
      fetchAllLocation(),
      fetchAllDeveloper(),
    ]);
  }, []);
  const { profile } = useProfile();
  const {
    register,
    watch,
    setValue,
    getValues,
    getFieldState,
    formState: { errors },
    control,
  } = useFormContext();

  const manualTotalUnitsCount = watch("manualTotalUnitsCount");
  const currentAvailableUnitsCount = watch("manualAvailableUnitsCount");

  useEffect(() => {
    if (!getFieldState("manualAvailableUnitsCount").isDirty) {
      setValue("manualAvailableUnitsCount", manualTotalUnitsCount);
    }
  }, [
    setValue,
    manualTotalUnitsCount,
    getFieldState,
    currentAvailableUnitsCount,
  ]);

  return (
    <FormGroup
      title="Information"
      description="Information of this project"
      className={cn(className)}
    >
      <Input
        label="Name"
        placeholder="Name of the project"
        disabled={profile?.role === Roles.AGENCY}
        {...register("name")}
        error={errors.name?.message as string}
      />
      <Input
        label="Area"
        min={0}
        disabled={profile?.role === Roles.AGENCY}
        type="number"
        placeholder="0.00"
        {...register("area")}
        error={errors.area?.message as string}
      />
      <Controller
        name="tenure"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            dropdownClassName="!z-0"
            options={tenureOptions}
            value={value}
            onChange={onChange}
            disabled={profile?.role === Roles.AGENCY}
            label="Tenure"
            error={errors?.tenure?.message as string}
            getOptionValue={(option) => option.value}
            displayValue={(selected: string) => {
              const selectedOption = tenureOptions?.find(
                (option) => option.value === selected
              );
              return selectedOption?.label;
            }}
          />
        )}
      />
      <Controller
        name="developerId"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            dropdownClassName="!z-0"
            options={developerOptions}
            value={value}
            disabled={profile?.role === Roles.AGENCY}
            onChange={onChange}
            label="Developer"
            error={errors?.developerId?.message as string}
            getOptionValue={(option) => option.value}
            displayValue={(selected: string) => {
              const selectedOption = developerOptions?.find(
                (option) => option.value === selected
              );
              return selectedOption?.label;
            }}
          />
        )}
      />
      <Controller
        name="marketSegment"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            dropdownClassName="!z-0"
            options={marketSegmentOptions}
            value={value}
            disabled={profile?.role === Roles.AGENCY}
            onChange={onChange}
            label="Market Segment"
            error={errors?.marketSegment?.message as string}
            getOptionValue={(option) => option.value}
            displayValue={(selected: string) => {
              const selectedOption = marketSegmentOptions?.find(
                (option) => option.value === selected
              );
              return selectedOption?.label;
            }}
          />
        )}
      />
      <Controller
        name="categoryId"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            options={categoyOptions ?? []}
            value={value}
            disabled={profile?.role === Roles.AGENCY}
            onChange={onChange}
            label="Category"
            error={errors?.categoryId?.message as string}
            getOptionValue={(option) => option.value}
            inPortal={false}
            suffix={loading ? <LoaderIcon /> : undefined}
            displayValue={(selected: string) => {
              const selectedOption = categoyOptions?.find(
                (option) => option.value === selected
              );
              return selectedOption?.label;
            }}
          />
        )}
      />
      <Controller
        name="locationId"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            options={locationOptions ?? []}
            value={value}
            onChange={onChange}
            label="Location"
            disabled={profile?.role === Roles.AGENCY}
            error={errors?.locationId?.message as string}
            getOptionValue={(option) => option.value}
            inPortal={false}
            suffix={loadingLocation ? <LoaderIcon /> : undefined}
            displayValue={(selected: string) => {
              const selectedOption = locationOptions?.find(
                (option) => option.value === selected
              );
              return selectedOption?.label;
            }}
          />
        )}
      />
      <div className="[&>.react-datepicker-wrapper]:w-full">
        <Controller
          name="tenureEffectFrom"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              disabled={profile?.role === Roles.AGENCY}
              inputProps={{
                label: "Tenure Effect From",
                error: errors?.tenureEffectFrom?.message as string,
              }}
              placeholderText="Select Date"
              selected={value}
              onChange={onChange}
            />
          )}
        />
      </div>{" "}
      <div className="[&>.react-datepicker-wrapper]:w-full">
        <Controller
          name="expectedTop"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              disabled={profile?.role === Roles.AGENCY}
              inputProps={{
                label: "TOP",
                error: errors?.expectedTop?.message as string,
              }}
              placeholderText="Select Date"
              selected={value}
              onChange={onChange}
              dateFormat="MMM, yyyy"
              showMonthYearPicker
            />
          )}
        />
      </div>
      <div className="[&>.react-datepicker-wrapper]:w-full">
        <Input
          label="Direct Commission"
          min={0}
          disabled={profile?.role === Roles.AGENCY}
          type="number"
          placeholder="0.00"
          {...register("directCommission")}
          error={errors.directCommission?.message as string}
        />
      </div>
      <Input
        label="Total units"
        min={0}
        disabled={profile?.role === Roles.AGENCY}
        type="number"
        placeholder=""
        {...register("manualTotalUnitsCount")}
        error={errors.area?.message as string}
      />
      <Input
        label="Available units"
        min={0}
        disabled={profile?.role === Roles.AGENCY}
        type="number"
        placeholder=""
        {...register("manualAvailableUnitsCount")}
        error={errors.area?.message as string}
      />
      <Input
        label="Address"
        className="col-span-full"
        placeholder="Address"
        disabled={profile?.role === Roles.AGENCY}
        {...register("address")}
        error={errors.address?.message as string}
      />
      <Controller
        control={control}
        name="description"
        render={({ field: { onChange, value } }) => (
          <QuillEditor
            value={value}
            onChange={onChange}
            readOnly={profile?.role === Roles.AGENCY}
            label="Description"
            className="col-span-full [&_.ql-editor]:min-h-[100px]"
            labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
            error={errors?.description?.message as string}
          />
        )}
      />
      <Controller
        control={control}
        name="amenityHtml"
        render={({ field: { onChange, value } }) => (
          <QuillEditor
            value={value}
            onChange={onChange}
            readOnly={profile?.role === Roles.AGENCY}
            label="Amenity"
            className="col-span-full [&_.ql-editor]:min-h-[100px]"
            labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
            error={errors?.amenityHtml?.message as string}
          />
        )}
      />
      <Controller
        control={control}
        name="facilityHtml"
        render={({ field: { onChange, value } }) => (
          <QuillEditor
            value={value}
            onChange={onChange}
            readOnly={profile?.role === Roles.AGENCY}
            label="Facility"
            className="col-span-full [&_.ql-editor]:min-h-[100px]"
            labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
            error={errors?.facilityHtml?.message as string}
          />
        )}
      />
      <Input
        label="Google Map Url"
        className="col-span-full"
        placeholder="Google Map Url"
        disabled={profile?.role === Roles.AGENCY}
        {...register("googleMapUrl")}
        error={errors.googleMapUrl?.message as string}
      />
    </FormGroup>
  );
}
