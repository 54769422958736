import dayjs from "dayjs";
import { createWhatsappMessage } from "@/utils/string";
import { formatDate } from "@/utils/format-date";
import { DATE_FORMAT } from "@/config/constants";
import { IProject } from "../types/Project";
import { IUserData } from "../types/Users";

interface GenerateMessageProps {
  name: string;
  email: string;
  phone: string;
  user?: IUserData;
  project?: IProject;
  appointment?: string;
}

function generateParsedMessage(
  templateContent: string,
  { name, email, phone, user, project, appointment }: GenerateMessageProps
): string {
  const parsedMessage = createWhatsappMessage(templateContent, {
    "Recipient's Name": name || "",
    customer_name: name || "",
    customer_email: email || "",
    customer_phone: phone || "",
    agent_name: `${user?.firstName ? `${user.firstName} ` : ""}${user?.lastName || ""}`,
    agent_email: user?.email || "",
    project_name:
      typeof project?.name === "object"
        ? project?.name?.en
        : project?.name || "",
    project_address: project?.address || "",
    project_area: project?.area || "",
    project_tenure: project?.tenure || "",
    project_tenure_effect_from:
      project?.tenureEffectFrom && dayjs(project?.tenureEffectFrom).isValid()
        ? dayjs(project.tenureEffectFrom).format("DD MMM YYYY")
        : "",
    appointment_date_time:
      appointment && dayjs(appointment).isValid()
        ? formatDate(new Date(appointment), DATE_FORMAT.DATE_TIME)
        : "",
  });

  return parsedMessage;
}

export default generateParsedMessage;
