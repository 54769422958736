"use client";

import DateFiled from "@/components/controlled-table/date-field";
import StatusField from "@/components/controlled-table/status-field";
import { IEnquiryEntry } from "@/utilities/types/EnquiryEntry";
import { useMemo } from "react";
import { PiMagnifyingGlassBold, PiTrashDuotone } from "react-icons/pi";
import { useMedia } from "react-use";
import { Input, Button, SelectOption, Title, Badge } from "rizzui";

type FilterElementProps = {
  isFiltered: boolean;
  filters: { [key: string]: any };
  updateFilter: (columnId: string, filterValue?: string | any[]) => void;
  handleReset: () => void;
  onSearch: (searchTerm: string) => void;
  searchTerm: string;
  handleSort?: (value: string) => void;
  onSendMessage: (leads: IEnquiryEntry[]) => void;
};

export default function FilterElement({
  isFiltered,
  handleReset,
  filters,
  updateFilter,
  onSearch,
  searchTerm,
  handleSort,
  onSendMessage,
}: FilterElementProps) {
  const isMediumScreen = useMedia("(max-width: 1860px)", false);

  const scoreRange = [
    { value: "fromScore=0", label: "All", color: "info" },
    { value: "fromScore=81", label: "Hot (> 80)", color: "success" },
    {
      value: "fromScore=50&toScore=80",
      label: "Warm (50 - 80)",
      color: "warning",
    },
    { value: "fromScore=0&toScore=49", label: "Cold (< 50)", color: "danger" },
    {
      value: "fromScore=0&toScore=0",
      label: "Agent/Duplicate",
      color: "primary",
    },
  ];

  type ScoreKey =
    | "fromScore=0"
    | "fromScore=81"
    | "fromScore=50&toScore=80"
    | "fromScore=0&toScore=49"
    | "fromScore=0&toScore=0";

  const scoreFields: Record<ScoreKey, { from: number; to: number | null }> = {
    "fromScore=0": { from: 0, to: null },
    "fromScore=81": { from: 81, to: null },
    "fromScore=50&toScore=80": { from: 50, to: 80 },
    "fromScore=0&toScore=49": { from: 0, to: 49 },
    "fromScore=0&toScore=0": { from: 0, to: 0 },
  };

  function processFilterScore(key: ScoreKey) {
    if (key in scoreFields) {
      const { from, to } = scoreFields[key as ScoreKey];
      updateFilter("fromScore", from.toString());
      updateFilter("toScore", to?.toString());
    } else {
      updateFilter("fromScore", "0");
    }
    return handleSort?.("score");
  }

  const selectedScoreValue = useMemo(() => {
    let value = `fromScore=${filters?.["fromScore"] ?? 0}`;
    if (filters?.["toScore"]) {
      value += `&toScore=${filters?.["toScore"]}`;
    }
    return value;
  }, [filters]);

  return (
    <div className="mb-4">
      <div className="flex md:flex-row mb-4 items-end justify-between flex-col w-full flex-wrap gap-4">
        <div className="flex md:flex-row items-end flex-col w-full md:w-fit gap-2">
          <DateFiled
            className="w-full"
            selected={filters?.["startDate"]}
            selectsRange={false}
            onChange={(date: any) => {
              updateFilter("startDate", date);
            }}
            placeholderText="Select created date"
            {...(isMediumScreen && {
              inputProps: {
                label: "Start Date",
                labelClassName: "font-medium text-gray-700",
              },
            })}
          />
          <DateFiled
            className="w-full"
            selected={filters?.["endDate"]}
            onChange={(date: any) => {
              updateFilter("endDate", date);
            }}
            selectsRange={false}
            placeholderText="Select created date"
            {...(isMediumScreen && {
              inputProps: {
                label: "End Date",
                labelClassName: "font-medium text-gray-700",
              },
            })}
          />
          <div className="flex w-full md:w-fit relative h-fit">
            <StatusField
              label="Filter by Score"
              className="md:min-w-[200px] md:w-fit h-fit w-full"
              options={scoreRange ?? []}
              value={selectedScoreValue}
              dropdownClassName="!z-10"
              placeholder="Filter by Score"
              getOptionValue={(option: SelectOption) => option.value}
              displayValue={(selected: string) => {
                const selectedOption = scoreRange?.find(
                  (option) => option.value === selected
                );
                return selectedOption?.label ? (
                  <div className="flex items-center justify-start gap-1">
                    <Badge
                      renderAsDot
                      color={
                        (selectedOption?.color as
                          | "info"
                          | "success"
                          | "warning"
                          | "danger"
                          | "primary"
                          | "secondary") || "primary"
                      }
                    />
                    {selectedOption?.label}
                  </div>
                ) : (
                  <span>Filter by Score</span>
                );
              }}
              getOptionDisplayValue={(selected: SelectOption) => {
                const selectedOption = scoreRange?.find(
                  (option) => option.value === selected.value
                );
                return (
                  <div className="flex items-center justify-start gap-1">
                    <Badge
                      renderAsDot
                      color={
                        (selectedOption?.color as
                          | "info"
                          | "success"
                          | "warning"
                          | "danger"
                          | "primary"
                          | "secondary") || "primary"
                      }
                    />
                    {selectedOption?.label}
                  </div>
                );
              }}
              onChange={(score: ScoreKey) => {
                processFilterScore(score);
              }}
            />
          </div>

          {isFiltered && (
            <Button
              size="sm"
              onClick={handleReset}
              variant="flat"
              className="self-end"
            >
              <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Clear
            </Button>
          )}
        </div>
        <Input
          type="search"
          placeholder="Search by customer's name, email..."
          value={searchTerm}
          onClear={() => onSearch("")}
          onChange={(event) => onSearch(event.target.value)}
          prefix={<PiMagnifyingGlassBold className="h-4 w-4" />}
          rounded="lg"
          clearable
          className="md:w-fit min-w-[300px] w-full"
        />
        {/* <div className="flex flex-end w-full grow-0 md:w-fit">
        <CustomPopover
          title={`Add all Projects`}
          description={`This action will add all projects to your project listing. Are you sure you want to add?`}
          onAccept={async () => onSendMessage()}
          className="ml-auto w-full md:w-fit hover:!border-transparent h-fit border-none"
        >
          <div className="rizzui-button inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-[1.8px] focus-visible:ring-offset-2 ring-offset-background transition-colors duration-200 px-5 py-1.5 text-base h-full rounded-md border border-transparent dark:backdrop-blur bg-primary hover:bg-primary-dark dark:hover:bg-primary/90 focus-visible:ring-muted text-primary-foreground w-full">
            <PiPlusBold className="me-1.5 h-[17px] w-[17px] " />
            Send message
          </div>
        </CustomPopover>
      </div> */}
      </div>
    </div>
  );
}
