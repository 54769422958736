import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function convertToSingaporeTime(date: Date): string {
  try {
    // Validate input is a Date object
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      throw new Error("Invalid date format");
    }

    // Format the date to Singapore time
    return dayjs(date).tz("Asia/Singapore").format("MMMM DD, YYYY hh:mm A"); // Example format: November 23, 2024 01:50 PM
  } catch (error) {
    console.error("Error converting date:", error.message);
    return "Invalid date";
  }
}

// Test the function
const testDate = new Date("2024-11-23T05:50:14.845Z");
console.log(convertToSingaporeTime(testDate));
