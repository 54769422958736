"use client";

import AddNewButton from "@/app/shared/add-new-button";
import { routes } from "@/config/routes";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import { Title, Input } from "rizzui";

type FilterElementProps = {
  onSearch: (searchTerm: string) => void;
  searchTerm: string;
  variant?:
    | "users"
    | "unit-transaction"
    | "unit-type"
    | "site-content"
    | "enquiry-entries";
};

export default function FilterElement({
  onSearch,
  searchTerm,
  variant,
}: FilterElementProps) {
  return (
    <div className="mb-4">
      <div className="relative flex z-50 mb-4 w-full flex-col md:flex-row flex-wrap items-center justify-between gap-2.5 @container ">
        <div className="flex flex-1 w-full flex-row gap-2 items-center justify-between">
          <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
            My Team
          </Title>
          {variant === "users" && (
            <div className="flex flex-row justify-end md:hidden">
              <AddNewButton
                navigateTo={routes.user.create}
                className="max-w-max"
              >
                Create User
              </AddNewButton>
            </div>
          )}
        </div>
        <div className="flex flex-1 w-full flex-col md:flex-row gap-2">
          <Input
            type="search"
            placeholder="Search by name, email..."
            value={searchTerm}
            onClear={() => onSearch("")}
            onChange={(event) => onSearch(event.target.value)}
            prefix={<PiMagnifyingGlassBold className="h-4 w-4" />}
            rounded="lg"
            clearable
            className="w-full @xl:ms-auto ml-auto @xl:w-auto  @4xl:w-[230px] @5xl:w-auto"
          />
          {variant === "users" && (
            <div className="md:flex flex-row hidden justify-end">
              <AddNewButton
                navigateTo={routes.user.create}
                className="max-w-max"
              >
                Create User
              </AddNewButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
