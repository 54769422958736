import UserRepository from "@/utilities/repositories/Users";
import { useEffect, useState, forwardRef } from "react";
import { Input, Select, SelectOption, SelectProps } from "rizzui";

type Props = Omit<SelectProps<SelectOption>, "options"> & {
  projectId?: string;
  disabled?: boolean;
};

const UserSelect = forwardRef<HTMLDivElement, Props>(
  ({ onChange, disabled, projectId, ...props }, ref) => {
    const [options, setOptions] = useState<Array<SelectOption>>([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
      const fetchOptions = async () => {
        let totalPage = 1;
        let currPage = 1;
        let arr: SelectOption[] = [];
        let res = null;

        do {
          if (projectId) {
            res = await UserRepository.getUsers({
              projectId,
              queryParams: {
                pagination: {
                  limit: 50,
                  page: currPage,
                },
              },
            });
          } else {
            res = await UserRepository.getUsers({
              queryParams: {
                pagination: {
                  limit: 50,
                  page: currPage,
                },
              },
            });
          }

          currPage = res.data.currentPage + 1;
          totalPage = res.data.totalPage;
          arr = arr.concat(
            res.data.items.map(
              (item: {
                firstName: string | { en: string };
                lastName: string | { en: string };
                email: string;
                id: string;
              }) => ({
                label:
                  typeof item.firstName === "string" &&
                  typeof item.lastName === "string"
                    ? item.firstName + " " + item.lastName + " " + item.email
                    : `${typeof item.firstName === "object" ? item.firstName.en : ""} ${typeof item.lastName === "object" ? item.lastName.en : ""} ${item.email}` ||
                      "Unknown",
                value: item.id,
              })
            )
          );
        } while (currPage <= totalPage);

        setOptions(arr);
      };

      fetchOptions();
    }, []);

    const renderSearchInput = (option: SelectOption) => {
      if (option.value !== "search") {
        return option.label;
      }
      return (
        <div className="w-full z-50 p-2">
          <Input
            type="search"
            placeholder="Search by email"
            value={search}
            onClear={() => setSearch("")}
            onChange={(event) => setSearch(event.target.value)}
            rounded="lg"
            clearable
            className="w-full"
          />
        </div>
      );
    };

    return (
      <div ref={ref}>
        {" "}
        {/* Attach the ref to this wrapper div */}
        <Select
          {...props}
          options={[
            { label: "Search", value: "search", disabled: true },
            ...options
              .filter((option) =>
                option.label.toLowerCase().includes(search.toLowerCase())
              )
              .sort((a, b) => {
                return a.label.localeCompare(b.label);
              }),
          ]}
          onChange={(e) => {
            onChange?.(e);
            setSearch("");
          }}
          disabled={disabled}
          optionClassName="first:bg-white first:p-0 first:sticky first:top-[-6px] first:[&>div]:w-full"
          getOptionDisplayValue={renderSearchInput}
          getOptionValue={(option) => option.value}
          displayValue={(selected) =>
            options?.find((r) => r.value === selected)?.label ??
            props.placeholder
          }
        />
      </div>
    );
  }
);

UserSelect.displayName = "UserSelect";

export default UserSelect;
