export default function formatDateTime(input: string): string {
  try {
    // Parse the input string into a Date object
    const date = new Date(input);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format");
    }

    // Extract the year, month, day, hour, and minute
    const year = date.getUTCFullYear();
    const month = date.toLocaleString("en-US", {
      month: "long",
      timeZone: "UTC",
    });
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    // Determine AM/PM
    const hour12 = parseInt(hours, 10) % 12 || 12;
    const ampm = parseInt(hours, 10) >= 12 ? "PM" : "AM";

    // Format the output as "MMMM DD, YYYY hh:mm A"
    return `${month} ${day}, ${year} ${hour12}:${minutes} ${ampm}`;
  } catch (error) {
    console.error("Error formatting date:", error.message);
    return "Invalid date";
  }
}
