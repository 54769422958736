import React from "react";
import DeletePopover from "@/app/shared/delete-popover";
import PencilIcon from "@/components/icons/pencil";
import { HeaderCell } from "@/components/ui/table";
import { routes } from "@/config/routes";
import { SortConfig } from "@/hooks/useTableServer";
import { getParamsCurrentPage } from "@/utilities/functions";
import { IUserData, Roles } from "@/utilities/types/Users";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { ActionIcon, Tooltip, Button, Input } from "rizzui";

import formatPhone from "@/utilities/functions/format-phone";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { TUserSchemaInput, userSchema } from "@/utilities/validators/user";
import { zodResolver } from "@hookform/resolvers/zod";

type UserSortBy = "email" | "location" | "phone";

type Columns = {
  data: IUserData[];
  sortConfig?: SortConfig<UserSortBy>;
  onDeleteItem: (id: string) => void;
  onHeaderCellClick?: (value: string) => void;
  onSetPassword?: (id: string, password: string) => void;
  profile: IUserData | null;
};

// Component for handling password input and submission
const PasswordForm = ({
  rowId,
  onSetPassword,
}: {
  rowId: string;
  onSetPassword?: (id: string, password: string) => void;
}) => {
  const form = useForm<TUserSchemaInput>({
    resolver: zodResolver(userSchema),
    defaultValues: { password: "" },
  });

  const handleSetPassword = (data: { password: string }) => {
    if (onSetPassword) {
      onSetPassword(rowId, data.password);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        className="flex gap-2 items-center justify-center"
        onSubmit={form.handleSubmit(handleSetPassword)}
      >
        <Controller
          name="password"
          control={form.control}
          render={({ field, fieldState }) => (
            <Input
              size="sm"
              placeholder="Enter password"
              value={field.value || ""}
              className="[&>label>span]:font-medium"
              onChange={field.onChange}
              error={fieldState.error?.message}
            />
          )}
        />
        <Button
          type="submit"
          color="primary"
          disabled={
            !form.watch("password") || form.watch("password").length < 6
          }
          size="sm"
        >
          Submit
        </Button>
      </form>
    </FormProvider>
  );
};

// Table columns definition
export const getColumns = ({
  sortConfig,
  onDeleteItem,
  onHeaderCellClick,
  onSetPassword,
  profile,
}: Columns) => {
  return [
    {
      title: <HeaderCell title="Name" />,
      dataIndex: "lastName",
      key: "lastName",
      render: (_: string, row: IUserData) => {
        return `${row.firstName} ${row.lastName}`;
      },
    },

    profile?.role === Roles.AGENCY && {
      title: <HeaderCell title="Password" align="center" />,
      dataIndex: "password",
      key: "password",
      align: "center",
      onHeaderCell: () => onHeaderCellClick?.("password"),
      render: (_: string, row: IUserData) => (
        <PasswordForm rowId={row.id} onSetPassword={onSetPassword} />
      ),
    },

    {
      title: (
        <HeaderCell
          title="Email"
          sortable
          align="center"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "email"
          }
        />
      ),
      dataIndex: "email",
      key: "email",
      align: "center",
      onHeaderCell: () => onHeaderCellClick?.("email"),
    },

    {
      title: (
        <HeaderCell
          title="Phone"
          sortable
          align="center"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "phone"
          }
        />
      ),
      dataIndex: "phone",
      key: "phone",
      align: "center",
      onHeaderCell: () => onHeaderCellClick?.("phone"),
      render: (_: string, row: IUserData) => {
        return <div>{formatPhone(row.phone, "+65")}</div>;
      },
    },

    {
      title: (
        <HeaderCell
          title="Registered"
          sortable
          align="center"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "createdAt"
          }
        />
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      onHeaderCell: () => onHeaderCellClick?.("createdAt"),
      render: (_: string, row: IUserData) => {
        return dayjs(row.createdAt).format("MMMM DD, YYYY");
      },
    },

    {
      title: (
        <HeaderCell
          className="ps-3"
          title={<span className="whitespace-nowrap">Actions</span>}
        />
      ),
      dataIndex: "action",
      key: "action",
      width: 120,
      render: (_: string, row: IUserData) => {
        return (
          <div className="flex items-center justify-end gap-3 pe-4">
            <Tooltip
              size="sm"
              content={"Edit User"}
              placement="top"
              color="invert"
            >
              <Link
                to={{
                  pathname: routes.user.edit(row.id),
                  search: getParamsCurrentPage({ rowKey: row?.id }),
                }}
              >
                <ActionIcon size="sm" variant="outline">
                  <PencilIcon className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>

            <DeletePopover
              disabled={row.role === Roles.ADMIN}
              title={`Delete User`}
              description={`Are you sure you want to delete this user?`}
              onDelete={() => onDeleteItem(row.id)}
            />
          </div>
        );
      },
    },
  ].filter(Boolean); // Remove falsy values (e.g., Password column for non-agency roles)
};
