import UpdateTeamMember from "./update-team-member";
import { Button, Title } from "rizzui";
import { Controller, useForm } from "react-hook-form";
import { ISchedule, IScheduleDTO } from "@/utilities/types/RoundRobin";
import { DatePicker } from "@/components/ui/datepicker";
import { zodResolver } from "@hookform/resolvers/zod";
import { timeSlotSchema } from "@/utils/validators/schedule.schema";
import { useModal } from "@/app/shared/modal-views/use-modal";
import moment from "moment";
import useApi from "@/hooks/useApi";
import RoundRobinRepository from "@/utilities/repositories/RoundRobin";
import toast from "react-hot-toast";

export interface TimeSlotDTOProps {
  defaultValues?: ISchedule;
  refetchData: () => void;
  isUpdate?: boolean;
}

function roundToNearest15Minutes(date: Date) {
  const msIn15Minutes = 15 * 60 * 1000; // 15 minutes in milliseconds
  return new Date(Math.round(date.getTime() / msIn15Minutes) * msIn15Minutes);
}

export default function TimeSlotDTO({
  defaultValues,
  refetchData,
  isUpdate,
}: TimeSlotDTOProps) {
  const { closeModal } = useModal();
  const defaultsMemberIds = defaultValues?.users?.map((item) => item.id) || [];

  const methods = useForm<IScheduleDTO>({
    defaultValues: {
      agentIds: defaultsMemberIds || [],
      startTime: moment(defaultValues?.startTime, "HH:mm").toISOString(),
      endTime: moment(defaultValues?.endTime, "HH:mm").toISOString(),
    },
    reValidateMode: "onChange",
    mode: "all",
    resolver: zodResolver(timeSlotSchema),
  });

  const { request: addTimeSlot } = useApi({
    request: RoundRobinRepository.addTimeSlot,
    enableToast: true,
  });

  const { request: updateAgentsInTimeSlot } = useApi({
    request: RoundRobinRepository.updateAgentsInTimeSlot,
    enableToast: true,
  });

  const onSubmit = async (value: IScheduleDTO) => {
    try {
      const bodyFormat: IScheduleDTO = {
        ...value,
        startTime: moment(value.startTime).format("HH:mm"),
        endTime: moment(value.endTime).format("HH:mm"),
      };
      if (isUpdate && defaultValues?.id) {
        await updateAgentsInTimeSlot(defaultValues?.id, {
          agentIds: bodyFormat.agentIds,
        });
      } else {
        await addTimeSlot(bodyFormat);
      }
      await refetchData();
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form
      onSubmit={methods.handleSubmit(onSubmit)}
      className="p-6 flex flex-col gap-6"
    >
      <div className="col-span-full flex items-center justify-between">
        <Title as="h4" className="font-semibold">
          {isUpdate ? "Update Time Slot" : "Add new Time slot"}
        </Title>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-4">
          <Controller
            control={methods.control}
            name="startTime"
            render={({ field: { value, onChange }, fieldState, formState }) => {
              return (
                <DatePicker
                  className="flex-1"
                  inputProps={{
                    label: "Start Time",
                    error: fieldState.error?.message,
                  }}
                  selected={value ? new Date(value) : undefined}
                  onChange={(date: Date) => {
                    const dateRound = roundToNearest15Minutes(date);
                    onChange(dateRound.toString());
                    methods.trigger(["endTime", "startTime"]);
                  }}
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  timeIntervals={15} // 15 min
                  placeholderText="Select Time"
                  showTimeSelect
                  showTimeSelectOnly
                  disabled={isUpdate} // Be default agency can't change time slot
                />
              );
            }}
          />

          <Controller
            control={methods.control}
            name="endTime"
            render={({ field: { value, onChange }, fieldState, formState }) => {
              return (
                <DatePicker
                  inputProps={{
                    label: "End Time",
                    error: fieldState.error?.message,
                  }}
                  selected={value ? new Date(value) : undefined}
                  onChange={(date: Date) => {
                    const dateRound = roundToNearest15Minutes(date);
                    onChange(dateRound.toString());
                    methods.trigger(["endTime", "startTime"]);
                  }}
                  timeIntervals={15} // 15 min
                  dateFormat="HH:mm"
                  placeholderText="Select Time"
                  timeFormat="HH:mm"
                  showTimeSelect
                  showTimeSelectOnly
                  className="flex-1"
                  disabled={isUpdate} // Be default agency can't change time slot
                />
              );
            }}
          />
        </div>
        <Controller
          name="agentIds"
          control={methods.control}
          render={({ field: { value, onChange }, fieldState, formState }) => {
            return (
              <UpdateTeamMember
                selectedMember={value}
                onChange={onChange}
                error={fieldState?.error?.message}
              />
            );
          }}
        />
      </div>

      <div className="col-span-full flex items-center justify-end gap-4 mt-6">
        <Button variant="outline" onClick={closeModal} className="w-fit">
          Cancel
        </Button>
        <Button
          type="submit"
          // isLoading={isLoading}
          className="w-fit"
        >
          {isUpdate ? "Edit" : "Create"}
        </Button>
      </div>
    </form>
  );
}
