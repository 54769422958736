"use client";

import { useModal } from "@/app/shared/modal-views/use-modal";
import EyeIcon from "@/components/icons/eye";
import { HeaderCell } from "@/components/ui/table";
import { SortConfig } from "@/hooks/useTableServer";
import { IEnquiryEntry } from "@/utilities/types/EnquiryEntry";
import dayjs from "dayjs";
import { ActionIcon, Checkbox, Text, Tooltip } from "rizzui";
import EnquiryEntryDetail from "../details/enquiry-entries-details";
import DeletePopover from "@/app/shared/delete-popover";
import { PiWarningLight } from "react-icons/pi";
import { IUserFeature } from "@/utilities/types/AdvanceFeature";
import convertToSingaporeTime from "@/utilities/functions/convertToSingaporeTime";
import formatPhone from "@/utilities/functions/format-phone";

type ContactSortBy = "project" | "createdAt" | "name" | "score";

const getScoreColor = (score: number) => {
  if (score > 80) return "green";
  if (score > 50) return "orange";
  if (score > 1) return "red";
  return "red"; // Score of 1, default case
};

export type Columns = {
  data: IEnquiryEntry[];
  sortConfig?: SortConfig<ContactSortBy>;
  onDeleteItem: (id: string) => void;
  onHeaderCellClick?: (value: string) => void;
  isAdmin: boolean;
  handleSelectAll?: any;
  checkedItems?: string[];
  onChecked?: (rowId: string) => void;
  multipleContact: IUserFeature | undefined;
};

export const getColumns = ({
  data,
  sortConfig,
  onDeleteItem,
  onHeaderCellClick,
  isAdmin,
  checkedItems,
  handleSelectAll,
  onChecked,
  multipleContact,
}: Columns) => {
  const columns = [
    {
      title: (
        <HeaderCell
          title={
            <div className="ps-2">
              <Checkbox
                title={""}
                onChange={() => handleSelectAll()}
                checked={checkedItems?.length === data.length}
                className="cursor-pointer"
              />
            </div>
          }
          align="center"
        />
      ),
      align: "center",
      dataIndex: "selectAll",
      key: "selectAll",

      width: 100,
      render: (_: unknown, row: IEnquiryEntry) => {
        return (
          <div className="flex justify-center">
            <Checkbox
              className="ps-2"
              aria-label="Select row"
              defaultValue={row.id}
              checked={checkedItems?.includes(row.id)}
              {...(onChecked && {
                onChange: (e) => {
                  onChecked(row?.id);
                },
              })}
            />
          </div>
        );
      },
    },
    {
      title: (
        <HeaderCell
          title="Customer"
          sortable
          align="center"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "name"
          }
        />
      ),

      dataIndex: "name",
      key: "name",
      align: "center",
      onHeaderCell: () => onHeaderCellClick?.("name"),

      render: (_: unknown, row: IEnquiryEntry) => {
        return (
          <div>
            <Text className="text-sm font-medium text-gray-900 dark:text-gray-700">
              {row.name}
            </Text>
            {row.email && (
              <Text className="text-[13px] text-gray-500">{row.email}</Text>
            )}
            {row?.phone && (
              <Text className="text-[13px] text-gray-500">
                {formatPhone(row.phone)}
              </Text>
            )}
          </div>
        );
      },
    },

    {
      title: <HeaderCell title="Agent" />,
      dataIndex: "user",
      key: "user",
      render: (_: unknown, row: IEnquiryEntry) => {
        if (!row.user) return;

        const { firstName, lastName, email, phone } = row.user;

        const name = firstName + " " + lastName;

        return (
          <div>
            <Text className="text-sm font-medium text-gray-900 dark:text-gray-700">
              {name}
            </Text>
            {email && (
              <Text className="text-[13px] text-gray-500">{email}</Text>
            )}
            {phone && (
              <Text className="text-[13px] text-gray-500">
                {formatPhone(phone)}
              </Text>
            )}
          </div>
        );
      },
    },

    {
      dataIndex: "project",
      key: "project",
      title: (
        <HeaderCell
          title={<span className="whitespace-nowrap">Project</span>}
          sortable
          align="left"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "project"
          }
        />
      ),
      onHeaderCell: () => onHeaderCellClick?.("project"),
      render: (_: unknown, row: IEnquiryEntry) => {
        if (!row.user) return;

        return (
          <div>
            <Text className="text-sm font-medium text-gray-900 dark:text-gray-700">
              {typeof row?.project?.name === "object"
                ? row?.project?.name?.en
                : row?.project?.name}
            </Text>
            {row?.project?.address?.en && (
              <Text className="text-[13px] text-gray-500">
                {row?.project?.address?.en}
              </Text>
            )}
          </div>
        );
      },
    },

    {
      dataIndex: "score",
      key: "score",
      title: (
        <HeaderCell
          title={<span className="whitespace-nowrap">Score</span>}
          sortable
          align="center"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "score"
          }
        />
      ),
      onHeaderCell: () => onHeaderCellClick?.("score"),
      width: 120,
      render: (_: unknown, row: IEnquiryEntry) => {
        if (!row.user) return;

        return (
          <div>
            <Text
              className="text-sm flex items-center justify-center font-medium text-gray-900 dark:text-gray-700"
              style={{ color: getScoreColor(row?.score) }}
            >
              {row?.score}
              {row?.score === 0 && <PiWarningLight className="h-4 w-4" />}
            </Text>
          </div>
        );
      },
    },

    {
      title: (
        <HeaderCell
          title={<HeaderCell title="Submit At" />}
          sortable
          align="center"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "createdAt"
          }
        />
      ),
      onHeaderCell: () => onHeaderCellClick?.("createdAt"),
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",

      width: 250,
      render: (_: unknown, row: IEnquiryEntry) => {
        if (!dayjs(row?.createdAt).isValid()) return;
        return <div>{convertToSingaporeTime(new Date(row.createdAt))}</div>;
      },
    },

    {
      title: (
        <HeaderCell
          className="ps-3"
          title={<span className="whitespace-nowrap">Actions</span>}
        />
      ),
      dataIndex: "action",
      key: "action",
      width: 120,
      align: "center",
      render: (_: string, row: IEnquiryEntry) => (
        <RenderAction onDelete={onDeleteItem} row={row} isAdmin={isAdmin} />
      ),
    },
  ];

  let filterdColumns = isAdmin
    ? columns
    : columns.filter((column) => column.key !== "user");

  filterdColumns =
    multipleContact || isAdmin
      ? filterdColumns
      : filterdColumns.filter((column) => column.key !== "selectAll");

  return filterdColumns;
};

const RenderAction = ({
  onDelete,
  row,
  isAdmin,
}: {
  onDelete: (id: string) => void;
  row: IEnquiryEntry;
  isAdmin?: boolean;
}) => {
  const { openModal } = useModal();
  return (
    <div
      className="flex items-center justify-center gap-3 pe-4"
      onClick={(e) => e.stopPropagation()}
    >
      <Tooltip
        size="sm"
        content={"View Details"}
        placement="top"
        color="invert"
      >
        <ActionIcon
          as="button"
          size="sm"
          variant="outline"
          aria-label={"View Details"}
          className="hover:!border-gray-900 hover:text-gray-700"
          onClick={() =>
            openModal({
              view: <EnquiryEntryDetail data={row} isModalView />,
              customSize: "1000px",
              size: "xl",
            })
          }
        >
          <EyeIcon className="h-4 w-4" />
        </ActionIcon>
      </Tooltip>
      {isAdmin && (
        <DeletePopover
          title={`Delete Enquiry`}
          description={`Are you sure you want to delete this enquiry?`}
          onDelete={() => onDelete(row.id)}
        />
      )}
    </div>
  );
};
