import { IResponseData } from "@/utilities/types/requests";
import useApi from "./useApi";
import SettingsRepository from "@/utilities/repositories/Settings";
import { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";

export const useCheckUniqueDomain = ({
  domain,
  configId,
}: {
  domain: string;
  configId?: string; // user config id
}) => {
  const {
    request: checkAvailableDomain,
    error,
    response,
  } = useApi<
    IResponseData<{
      data: null;
      message: string;
      statusCode: number;
      success: boolean;
    }>
  >({
    request: SettingsRepository.checkAvailableDomain,
  });

  const [domainDebounce] = useDebounceValue(domain, 300);

  const [domainIsAvailable, setDomainIsAvailable] = useState<boolean>(false);

  useEffect(() => {
    if (!domainDebounce || !domain) return;

    checkAvailableDomain(domainDebounce, configId)
      .then((response) => {
        if (response?.statusCode === 200) {
          setDomainIsAvailable(true);
        }
      })
      .catch((error) => {
        setDomainIsAvailable(false);
      });
  }, [domainDebounce]);

  return { error, response, domainIsAvailable };
};
