import useApi from "@/hooks/useApi";
import UserRepository from "@/utilities/repositories/Users";
import { IResponseData } from "@/utilities/types/requests";
import { IUserData, IUserDataResponse } from "@/utilities/types/Users";
import cn from "@/utils/class-names";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  Loader,
  MultiSelect,
  MultiSelectOption,
  Tooltip,
} from "rizzui";

export interface UpdateTeamMemberProps {
  selectedMember: string[]; // ids
  onChange: (values: string[]) => void;
  error?: string;
}

export default function UpdateTeamMember({
  selectedMember,
  onChange,
  error,
}: UpdateTeamMemberProps) {
  const [myTeamMember, setMyTeamMember] = useState<IUserData[]>([]);

  const { request: searchMyUserList, loading } = useApi<
    IResponseData<IUserDataResponse>
  >({
    request: UserRepository.getTeamUsers,
  });

  const optionsMember: MultiSelectOption[] = useMemo(() => {
    if (myTeamMember.length === 0) return [];
    return myTeamMember.map((item) => ({
      label: item.firstName + item.lastName,
      value: item.id,
      ...item,
    }));
  }, [myTeamMember]);

  const findMyTeam = async (value?: string) => {
    try {
      const response = await searchMyUserList({
        limit: 50,
        page: 1,
        search: value || undefined,
      });

      setMyTeamMember(response?.data?.items || []);
    } catch (error) {}
  };

  const debounceFindMyTeam = debounce(async (value: string) => {
    try {
      await findMyTeam(value);
    } catch (error) {}
  }, 500);

  useEffect(() => {
    findMyTeam();
  }, []);

  return (
    <div className="space-y-2 h-full">
      <MultiSelect
        label="Team member"
        value={selectedMember || []}
        onChange={(values: string[]) => {
          onChange(values);
        }}
        searchable
        searchClassName="sticky"
        onSearchChange={async (value: string) => {
          await debounceFindMyTeam(value);
        }}
        placeholder="Please select your team members"
        clearable
        searchPlaceHolder="Search by member's name, email,..."
        searchSuffix={loading ? <Loader size="sm" /> : undefined}
        options={optionsMember}
        className={"w-full"}
        optionClassName="aria-[disabled]:bg-muted/70 aria-[disabled]:cursor-not-allowed"
        displayValue={(selected, options) => {
          const selectedString = options
            .filter((item) => selected.includes(item.value))
            .map((item) => item.label);
          return (
            <p className="w-full truncate">{selectedString?.join(", ")}</p>
          );
        }}
        inPortal
        getOptionDisplayValue={(option, selected) => {
          const ElementWrapper = option.disabled ? Tooltip : "div";
          return (
            <ElementWrapper
              content={
                option.disabled
                  ? "This user already selected for time slot"
                  : undefined
              }
            >
              <div className={cn("flex flex-row items-center w-full")}>
                <Checkbox
                  size="sm"
                  checked={selected}
                  className="pointer-events-none [&_svg]:top-0"
                />
                <span className="ml-2">{option?.label}</span>
              </div>
            </ElementWrapper>
          );
        }}
        error={error}
      />
    </div>
  );
}
