import DeletePopover from "@/app/shared/delete-popover";
import ModalButton from "@/app/shared/modal-button";
import { SortableList } from "@/components/dnd-sortable/dnd-sortable-list";
import useApi from "@/hooks/useApi";
import { ESectionLayout } from "@/utilities/enums/SectionLayouts";
import SectionSettingRepository from "@/utilities/repositories/SectionSetting";
import { ISectionConfig } from "@/utilities/types/Setting";
import {
  FieldArraySectionConfigInput,
  FieldArraySectionConfigSchema,
} from "@/utilities/validators/section-config";
import cn from "@/utils/class-names";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CgWebsite } from "react-icons/cg";
import { PiArrowsOutCardinalBold, PiCaretDownBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { Button, Collapse, Title } from "rizzui";
import CreateSectionConfigForm from "./forms/CreateSectionConfigForm";
import UpdateSectionConfigForm from "./forms/UpdateSectionConfigForm";
import CollapseOrderProject from "./setting-project/collapse-project-setting";
import TrashIcon from "@/components/icons/trash";
import VisibleToggle from "@/components/visible-toggle";
import { useAtom } from "jotai";
import { atomFetchLocation, atomHandleSelectLocation } from "./store";
import { useAdvanceFeatures } from "@/hooks/use-advance-features";
import { EFeatureMode } from "@/utilities/types/AdvanceFeature";
import { SectionSettingDTO } from "@/utilities/types/SectionSettings";
import { DragEndEvent } from "@dnd-kit/core";

interface ISectionConfigProps {
  previewUrl: string;
}

export default function SectionConfig({ previewUrl }: ISectionConfigProps) {
  const [sectionConfigs, setSectionConfigs] = React.useState<ISectionConfig[]>(
    []
  );

  const { dynamicLayoutMode } = useAdvanceFeatures();

  const [selectedLocation] = useAtom(atomHandleSelectLocation);

  const collapseInit = React.useRef<any>(null);

  const [, fetchLocation] = useAtom(atomFetchLocation);

  const [collapseRow, setCollapseRow] = React.useState<boolean>(false);
  const { request: fetchAllConfigs, response: dataConfigs } = useApi({
    request: SectionSettingRepository.getAllSections,
  });

  const { request: updateManySection, loading: updating } = useApi({
    request: SectionSettingRepository.updateManySection,
  });

  const { request: onArrangeSection } = useApi({
    request: SectionSettingRepository.onArrangeSection,
  });

  const { request: removeSection, loading: isDeleting } = useApi({
    request: SectionSettingRepository.removeSection,
  });

  useEffect(() => {
    fetchAllConfigs();
  }, []);

  useEffect(() => {
    setSectionConfigs([...(dataConfigs?.data?.items || [])]);
  }, [dataConfigs?.data?.items]);

  const refetchData = async () => {
    await fetchAllConfigs();
  };

  const methodsUpdate = useForm<FieldArraySectionConfigInput>({
    defaultValues: [],
    resolver: zodResolver(FieldArraySectionConfigSchema),
  });

  const onCloseCollapseRow = (rowId: string) => {
    const currentInit = collapseInit?.current?.[rowId];
    if (currentInit) {
      !!currentInit?.open && currentInit?.toggle();
    }
  };

  useEffect(() => {
    if (!sectionConfigs?.length) {
      return;
    }

    // transform name return from api
    const transformData = sectionConfigs?.map((item) => ({
      ...item,
      name: typeof item?.name === "object" ? item?.name?.en : item?.name,
      weightTimestamp: item?.weightTimestamp || "",
      photo: {
        id: item?.photo?.id || "",
        url: item?.photo?.urls?.[0]?.url || "",
      },
      ctaTitle: item?.cta?.title || "",
      ctaDescription: item?.cta?.description || "",
      ctaButtonTitle: item?.cta?.button?.title || "",
    }));

    methodsUpdate.reset(
      transformData as unknown as FieldArraySectionConfigInput
    );
  }, [sectionConfigs]);

  // async function handleOrderSection(event: DragEndEvent) {
  //   const { active, over } = event;
  //   if (!active || !over || active.id === over.id) return;
  //   const oldIndex = sectionConfigs.findIndex((item) => item.id === active.id);
  //   const newIndex = sectionConfigs.findIndex((item) => item.id === over.id);

  //   const oldData = sectionConfigs[oldIndex];
  //   const newData = sectionConfigs[newIndex];

  //   const swapData: Partial<SectionSettingDTO>[] = [
  //     {
  //       id: oldData.id,
  //       weight: newData.weight,
  //     },
  //     {
  //       id: newData.id,
  //       weight: oldData.weight,
  //     },
  //   ];

  //   await updateManySection(swapData);
  //   await refetchData();
  // }
  async function handleOrderSection({
    item,
    newIndex,
  }: {
    item: ISectionConfig;
    newIndex: number;
  }) {
    // NOTE: weight start from 1 and index start from 0
    const newWeight = newIndex + 1;
    const oldIndex = sectionConfigs?.findIndex(
      (sectionConfig) => sectionConfig?.id === item?.id
    );

    const oldData = sectionConfigs[newIndex];

    if (oldIndex === -1 || oldIndex === newIndex) return;
    const temp = [...sectionConfigs];

    // swap index
    [temp[oldIndex], temp[newIndex]] = [temp[newIndex], temp[oldIndex]];

    const swapData: Partial<SectionSettingDTO>[] = [
      {
        id: item.id,
        weight: newWeight,
      },
      {
        id: oldData.id,
        weight: item.weight,
      },
    ];

    updateManySection(swapData).then(() => {
      const tempUpdatePosition = temp.map((item, idx) => {
        return {
          ...item,
          weight: idx + 1,
        };
      });

      setSectionConfigs(tempUpdatePosition);
    });
  }

  async function onSubmit(values: FieldArraySectionConfigInput) {
    try {
      const transformValue = values.map((value) => {
        if (value?.layout === ESectionLayout?.SectionCTA) {
          return {
            ...value,
            photoId: value?.photo?.id || null, // use when layout == 'SectionCTA'
            cta: {
              title: value?.ctaTitle || "",
              description: value?.ctaDescription || "",
              button: {
                title: value?.ctaButtonTitle || "",
                backgroundColor: "",
                url: "",
              },
            },
            photo: undefined,
            ctaTitle: undefined,
            ctaDescription: undefined,
            ctaButtonTitle: undefined,
          };
        }
        return {
          ...value,
          ctaTitle: undefined,
          ctaDescription: undefined,
          ctaButtonTitle: undefined,
          photoId: undefined,
          photo: undefined,
        };
      });
      await updateManySection(transformValue);
      toast.success(`Updated successfully`);
      await refetchData();
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  }

  const onDeleteSection = async (id: string) => {
    try {
      await removeSection(id || "");
      toast.success(`Deleted successfully`);
      refetchData();
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    refetchData();
  }, [dynamicLayoutMode]);

  return (
    <>
      <div className="relative">
        <div className="text-right mb-4 flex justify-start gap-4">
          {dynamicLayoutMode === EFeatureMode.Advance && (
            <ModalButton
              className={
                "bg-white border border-solid border-black text-black hover:text-white w-fit m-0"
              }
              view={
                <div className="px-4 py-6">
                  <Title>
                    <h3 className="text-lg font-semibold mb-4">New section</h3>
                  </Title>
                  <CreateSectionConfigForm
                    refetchData={refetchData}
                    weight={(sectionConfigs?.length || 0) + 1}
                  />
                </div>
              }
            />
          )}
          {previewUrl && (
            <Link
              to={`https://${previewUrl}`}
              target="_blank"
              className={"h-auto block"}
            >
              {/* TODO: Link to agent's domain instead of hard code */}

              <Button
                className="h-10 text-xs sm:text-sm font-medium capitalize hover:text-black hover:bg-white border border-solid border-black transition-all"
                size="sm"
              >
                <div className="inline-flex items-center gap-x-2">
                  <CgWebsite />
                  Preview Site
                </div>
              </Button>
            </Link>
          )}
        </div>
        <FormProvider {...methodsUpdate}>
          <form noValidate>
            {sectionConfigs?.length > 0 && (
              <SortableList<ISectionConfig>
                items={sectionConfigs}
                onChange={
                  dynamicLayoutMode === EFeatureMode.Advance
                    ? handleOrderSection
                    : () => {}
                }
              >
                {sectionConfigs.map((item, index) => {
                  const isDefaultOpen = index === sectionConfigs.length - 1;
                  return (
                    <React.Fragment key={item.id}>
                      <SortableList.Item
                        className={cn(
                          "list-none border-muted py-4 relative mb-5 items-start rounded-lg border px-2 shadow @md:p-5 @xl:p-3 group",
                          updating &&
                            "opacity-70  select-none pointer-events-none bg-white z-100 transition "
                        )}
                        id={item.id}
                      >
                        <Collapse
                          data-state-open={collapseRow}
                          header={({ open, toggle }) => {
                            collapseInit.current = {
                              ...collapseInit.current,
                              [item.id]: {
                                open,
                                toggle,
                              },
                            };
                            return (
                              <button
                                type="button"
                                onClick={() => {
                                  toggle();
                                }}
                                className={cn(
                                  "flex w-full justify-between cursor-pointer items-center  text-start gap-x-2"
                                )}
                                data-state-open={open}
                              >
                                <div className="flex flex-col">
                                  <Title
                                    as="h3"
                                    className="text-base font-medium @2xl:mb-2"
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          typeof item?.name === "object"
                                            ? item?.name?.en || ""
                                            : item?.name || "",
                                      }}
                                    />
                                  </Title>
                                  {sectionConfigs[index]?.location && (
                                    <div className="my-2">
                                      <b>Location:</b>{" "}
                                      {sectionConfigs[index]?.location?.name
                                        ?.en || ""}
                                    </div>
                                  )}
                                </div>
                                <div className="inline-flex gap-x-2 items-center">
                                  <Controller
                                    control={methodsUpdate.control}
                                    name={`${index}.visible`}
                                    render={({ field: { value } }) => (
                                      <div className="relative h-full flex flex-col">
                                        <div className="flex-1 flex flex-col justify-center">
                                          <VisibleToggle
                                            value={!!value}
                                            onChange={(value: boolean) =>
                                              methodsUpdate.setValue(
                                                `${index}.visible`,
                                                value,
                                                { shouldDirty: true }
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                  />
                                  <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-gray-100">
                                    <PiCaretDownBold
                                      className={cn(
                                        "h-3.5 w-3.5 transform transition-transform duration-300",
                                        open && "rotate-180"
                                      )}
                                    />
                                  </div>
                                </div>
                              </button>
                            );
                          }}
                        >
                          <div className="">
                            <UpdateSectionConfigForm
                              indexForm={index}
                              sectionId={item?.id || ""}
                              sectionConfigs={sectionConfigs}
                            />

                            {![
                              ESectionLayout.SectionHTMLContent,
                              ESectionLayout.SectionCTA,
                              ESectionLayout.SectionPromotionBanner,
                              ESectionLayout.SectionFeaturedLaunches,
                            ]?.includes(
                              (methodsUpdate.watch(`${index}.layout`) ||
                                "") as ESectionLayout
                            ) && (
                              <CollapseOrderProject
                                sectionId={item.id || ""}
                                locationId={
                                  sectionConfigs[index]?.location?.id || ""
                                }
                                indexForm={index}
                                defaultOpen={isDefaultOpen}
                                previewUrl={previewUrl}
                              />
                            )}
                          </div>
                        </Collapse>
                        {dynamicLayoutMode === EFeatureMode.Advance && (
                          <button
                            className={cn(
                              "absolute -left-[26px] -top-[14px] hidden translate-x-full grid-cols-1 gap-0 overflow-hidden rounded-md border bg-white shadow-[0px_1px_4px_rgba(0,0,0,0.16)] group-hover:grid group-aria-[expanded=true]:hidden dark:border-muted/20"
                            )}
                            type="button"
                            data-id={item.id}
                            onMouseDown={() => {
                              onCloseCollapseRow(item.id);
                            }}
                          >
                            <SortableList.DragHandle className="flex h-auto w-full items-center justify-center p-1.5 text-lg hover:bg-gray-100 dark:text-gray-50 dark:hover:bg-gray-700">
                              <PiArrowsOutCardinalBold className="size-4" />
                            </SortableList.DragHandle>
                          </button>
                        )}

                        {/* <PiTrash className="size-6 absolute right-0 top-0 text-red-700 font-bold cursor-pointer" /> */}
                        {!item.location && (
                          <div className="size-10 absolute -right-[26px] -top-[14px] text-red-700 font-bold cursor-pointer">
                            <DeletePopover
                              title={`Delete section`}
                              description={`Are you sure you want to delete this section?`}
                              iconButtonClass="border-none"
                              icon={<TrashIcon className="w-[40px] h-auto" />}
                              isDeleting={isDeleting}
                              onDelete={() => onDeleteSection(item?.id || "")}
                            />
                          </div>
                        )}
                      </SortableList.Item>
                      {/* ACTTION */}
                    </React.Fragment>
                  );
                })}
              </SortableList>
            )}
          </form>
        </FormProvider>
      </div>

      <div
        className={cn(
          "col-span-full flex items-center justify-end gap-4 sticky py-4 bottom-0 left-0 right-0 z-[99]"
        )}
      >
        <Button
          type="submit"
          disabled={!methodsUpdate.formState.isDirty}
          onClick={methodsUpdate.handleSubmit(onSubmit)}
          isLoading={updating}
        >
          Update
        </Button>
      </div>
    </>
  );
}
