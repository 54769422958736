import { z } from "zod";
import moment from "moment";
export const timeSlotSchema = z
  .object({
    startTime: z
      .string({ message: "Select start time" })
      .optional()
      .default(""),
    endTime: z.string({ message: "Select end time" }).optional().default(""),
    agentIds: z
      .array(z.string())
      .nonempty({
        message: "This field is required",
      })
      .default([""]),
  })
  .superRefine((data, ctx) => {
    if (!data.startTime) {
      ctx.addIssue({
        message: "This field is required",
        path: ["startTime"],
        code: "custom",
      });
    }

    if (!data.endTime) {
      ctx.addIssue({
        message: "This field is required",
        path: ["endTime"],
        code: "custom",
      });
    }

    if (data.startTime && data.endTime) {
      const start = moment(data.startTime);
      const end = moment(data.endTime);

      if (end.isSameOrBefore(start)) {
        return ctx.addIssue({
          message: "End time must be greater than start time",
          path: ["endTime"], // Attach the error to endTime,
          code: "custom",
        });
      }
    }
  });
