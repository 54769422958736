export default function formatPhoneNumber(
  phone: string | null | undefined
): string {
  // Remove non-numeric characters except for "+"
  const cleanedPhone = phone?.replace(/[^\d+]/g, "");

  // Format the local number as XXXX YYYY
  const formattedLocalNumber = `${cleanedPhone?.slice(0, 4)} ${cleanedPhone?.slice(4, 8)}`;

  // Combine country code with formatted local number
  return `${formattedLocalNumber.trim()}`;
}
