import { z } from "zod";
import { messages } from "@/config/messages";
import { validateEmail } from "@/utils/validators/common-rules";

// form zod validation schema
export const createUserSchema = z.object({
  firstName: z.string().min(1, { message: messages.firstNameRequired }),
  lastName: z.string().min(1, { message: messages.lastNameRequired }),
  email: validateEmail,
  password: z.string().min(1),
  phone: z.string().optional(),
});

// generate form types from zod validation schema
export type CreateUserInput = z.infer<typeof createUserSchema>;
