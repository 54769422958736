import { Roles } from "@/utilities/types/Users.ts";
import { HeaderCell } from "@/components/ui/table.tsx";
import useProfile from "@/hooks/use-profile.ts";
import DeletePopover from "@/app/shared/delete-popover.tsx";
import ModalCreateEditFloor from "@/app/app-shared/floor-plan/create-edit/modal-edit.tsx";
import { ActionIcon, Select, SelectOption, Tooltip } from "rizzui";
import { Link } from "react-router-dom";
import { routes } from "@/config/routes";
import { BsBoxes } from "react-icons/bs";
import { getParamsCurrentPage } from "@/utilities/functions";
import CellEdit from "@/components/table-cell-custom/cell-edit";
import { IUnit, IUnitDTO } from "@/utilities/types/Units";
import { useState } from "react";
import CustomPopover from "@/app/shared/custom-popover";
import { BiCheck, BiPlus } from "react-icons/bi";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { unitSchema } from "@/utilities/validators/units";
import { Field } from "@headlessui/react";
import cn from "@/utils/class-names";
import { SortConfig } from "@/hooks/useTableServer";

type UnitsSortBy = "name" | "price" | "status";

const statusOptions = [
  {
    label: "Available",
    value: "available",
  },
  {
    label: "Sold",
    value: "sold",
  },

  {
    label: "Not Released",
    value: "not released",
  },
  {
    label: "Reserved",
    value: "reserved",
  },
];

type Columns = {
  data: IUnit[];
  sortConfig?: SortConfig<UnitsSortBy>;
  onDeleteItem?: (id: string) => void;
  onHeaderCellClick: (value: string) => void;
  onUpdateFloor?: (id: string) => Promise<void>;
  reset?: () => void;
  role: Roles;
  projectId: string;
  onUpdateItem: (
    id: string,
    data: Partial<IUnitDTO>
  ) => Promise<void> | undefined;
  isAdding: boolean;
  onAddUnit: (data: IUnitDTO) => Promise<void>;
};
export const GetColumns = ({
  data,
  sortConfig,
  onHeaderCellClick,
  onDeleteItem,
  role,
  reset,
  projectId,
  onUpdateItem,
  isAdding,
  onAddUnit,
}: Columns) => {
  const [addData, setAddData] = useState<IUnitDTO>({
    name: "",
    price: "",
    status: "available",
  });

  const form = useForm<IUnitDTO>({
    defaultValues: addData,
    mode: "onChange",
    resolver: zodResolver(unitSchema),
  });

  const onSubmit = async (values: IUnitDTO) => {
    await onAddUnit(values);
    form.reset({
      name: "",
      price: "",
      status: "available",
    });
    setAddData({
      name: "",
      price: "",
      status: "available",
    });
  };

  const { profile } = useProfile();

  return [
    {
      title: (
        <HeaderCell
          title="Name"
          sortable
          align="left"
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "name"
          }
        />
      ),
      onHeaderCell: () => onHeaderCellClick("name"),
      dataIndex: "name",
      key: "name",

      render: (name: string, row: IUnit & { isAdd?: boolean }) => {
        return (
          <Controller
            name="name"
            control={form.control}
            render={({ field, fieldState }) => {
              return (
                <CellEdit
                  defaultValue={name}
                  onSubmit={async (value) => {
                    if (row?.isAdd) {
                      setAddData({
                        ...addData,
                        name: value,
                      });
                      return;
                    }
                    await onUpdateItem(row.id, {
                      name: value,
                    });
                  }}
                  onChange={field.onChange}
                  defaultToggle={row.isAdd}
                  error={fieldState?.error?.message || ""}
                  readOnly={profile?.role !== Roles.ADMIN}
                />
              );
            }}
          />
        );
      },
    },

    {
      title: (
        <HeaderCell
          title="Price"
          sortable
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "price"
          }
        />
      ),
      onHeaderCell: () => onHeaderCellClick("price"),
      dataIndex: "price",
      key: "price",

      render: (price: string, row: IUnit & { isAdd?: boolean }) => {
        return (
          <Controller
            name="price"
            control={form.control}
            render={({ field, fieldState }) => {
              return (
                <CellEdit
                  defaultValue={price}
                  onSubmit={async (value) => {
                    if (row?.isAdd) {
                      setAddData({
                        ...addData,
                        price: value,
                      });
                      return;
                    }
                    await onUpdateItem(row.id, {
                      price: value,
                    });
                  }}
                  onChange={field.onChange}
                  defaultToggle={row.isAdd}
                  error={fieldState?.error?.message || ""}
                  readOnly={profile?.role !== Roles.ADMIN}
                  isNumber={true}
                />
              );
            }}
          />
        );
      },
    },
    {
      title: (
        <HeaderCell
          title="Status"
          sortable
          ascending={
            sortConfig?.direction === "asc" && sortConfig?.key === "status"
          }
        />
      ),
      onHeaderCell: () => onHeaderCellClick("status"),
      dataIndex: "status",
      key: "status",
      render: (
        status: "avaiable" | "sold" | "not released" | "reserved",
        row: IUnit & { isAdd?: boolean }
      ) => {
        const optionValue = row?.isAdd ? addData.status : status;
        return (
          <div className="flex justify-center items-center">
            <Controller
              name="status"
              control={form.control}
              render={({ field }) => {
                return (
                  <Select
                    options={statusOptions}
                    value={optionValue}
                    onChange={async (value: any) => {
                      if (row?.isAdd) {
                        setAddData({
                          ...addData,
                          status: value,
                        });
                        field.onChange(value);
                        return;
                      }
                      await onUpdateItem(row.id, {
                        status: value,
                      });
                    }}
                    size="sm"
                    getOptionValue={(option: SelectOption) => option.value}
                    displayValue={(selected: string) => {
                      const findOption = statusOptions?.find(
                        (option) => option.value === selected
                      );

                      return findOption?.label;
                    }}
                    className={cn(
                      profile?.role !== Roles.ADMIN && "pointer-events-none"
                    )}
                    dropdownClassName={"z-modal [&_li>div]:w-full"}
                  />
                );
              }}
            />
          </div>
        );
      },
    },

    {
      title: (
        <HeaderCell
          className="ps-3"
          title={<span className="whitespace-nowrap">Actions</span>}
        />
      ),
      dataIndex: "action",
      key: "action",
      hidden: profile?.role !== Roles.ADMIN,
      width: 120,
      render: (_: string, row: IUnit & { isAdd?: boolean }) => {
        if (role === Roles.ADMIN) {
          return (
            <div className="flex flex-row items-center justify-center">
              <ActionAdmin
                onDeleteItem={() => onDeleteItem?.(row.id)}
                row={row}
                projectId={projectId}
                reset={reset}
                isAdding={!!row?.isAdd}
              />
              {!!row?.isAdd && (
                <CustomPopover
                  title={`Add Unit`}
                  description={`Are you sure to add this unit?`}
                  onAccept={async () => {
                    form.handleSubmit(onSubmit)();
                  }}
                >
                  <Tooltip
                    size="sm"
                    content={"Submit"}
                    placement="top"
                    color="invert"
                  >
                    <ActionIcon size="sm" variant="text">
                      <BiCheck className="h-4 w-4" />
                    </ActionIcon>
                  </Tooltip>
                </CustomPopover>
              )}
            </div>
          );
        }
      },
    },
  ];
};
const ActionAdmin = ({
  onDeleteItem,
  row,
  projectId,
  reset,
  isAdding,
  onAdd,
}: {
  onDeleteItem: () => void;
  reset?: () => void;
  onAdd?: () => Promise<void>;
  row: IUnit;
  projectId: string;
  isAdding: boolean;
}) => {
  return (
    <div className="flex items-center justify-center gap-3 pe-4">
      <>
        <DeletePopover
          title={`Add new Unit`}
          description={`Are you sure to add this unit`}
          onDelete={onDeleteItem}
        />
      </>
    </div>
  );
};
