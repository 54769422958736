import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { UNITS } from "../enums/Units";
import { IUnit, IUnitDTO } from "../types/Units";

function getUnits({ queryParams }: any) {
  const query = {
    // sort: queryParams?.query?.sort,
    projectId:
      queryParams?.query?.projectId ||
      queryParams?.query?.filter?.[0]?.projectId,
    floorPlanId:
      queryParams?.query?.floorPlanId! ||
      queryParams?.query?.filter?.[1]?.floorPlanId,
    ...queryParams?.pagination,
  };
  return request<ListResponseData<IUnit>>({
    method: ERequestMethods.GET,
    url: UNITS.GET,
    params: query,
  });
}

function createUnit(data: IUnitDTO) {
  return request<IResponseData<IUnit>>({
    method: ERequestMethods.POST,
    url: UNITS.CREATE,
    data,
  });
}

function updateUnit(
  id: string,
  data: Partial<IUnitDTO & { projectId: string; floorPlanId: string }>
) {
  return request<IResponseData<IUnit>>({
    method: ERequestMethods.PATCH,
    url: UNITS.UPDATE(id),
    data,
  });
}

function removeUnit(id: string) {
  return request<IResponseData<IUnit>>({
    method: ERequestMethods.DELETE,
    url: UNITS.REMOVE(id),
  });
}

function getUnitDetail(id: string) {
  return request<IResponseData<IUnit>>({
    method: ERequestMethods.GET,
    url: UNITS.DETAIL(id),
  });
}

const UnitsRepository = Object.freeze({
  getUnits,
  createUnit,
  updateUnit,
  removeUnit,
  getUnitDetail,
});

export default UnitsRepository;
