import { Enum } from "./Enum";

export const E_USERS = Object.freeze({
  MAIN: "/users",
  MY_USERS: "/users/get-my-users",
  USER_DETAIL: "/users/:id",
  ME: "/users/me",
  SET_PASSWORD: "/users/set-password",
});

export type E_USERS = Enum<typeof E_USERS>;
