import { z } from "zod";

// form zod validation schema
export const whatsAppDataSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  email: z.string().min(1, { message: "Email is required" }),
  phone: z
    .string()
    .min(8, { message: "Phone number must be at least 8 characters" }),
  agentId: z.string().optional(),
  projectId: z.string().optional(),
  appointmentId: z.string().optional(),
  templateContent: z.string().optional(),
});

// generate form types from zod validation schema
export type WhatsAppDataSchema = z.infer<typeof whatsAppDataSchema>;
