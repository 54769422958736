import { useEffect, useState } from "react";
import { Button, Tab, Title } from "rizzui";
import { FormProvider, useForm } from "react-hook-form";
import TelegramConfigurationForm from "./features/TelegramConfigurationFormProps";
import cn from "@/utils/class-names";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  advanceFeatures,
  AdvanceFeaturesSchema,
} from "@/utils/validators/advance-features.schema";
import {
  EFeatureMode,
  EFeatureType,
  IUserFeature,
} from "@/utilities/types/AdvanceFeature";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import AdvanceFeatureRepository from "@/utilities/repositories/AdvanceFeature";
import useProfile from "@/hooks/use-profile";
import { ChatBotDTO } from "@/utilities/types/ChatBot";
import ChatBotRepositories from "@/utilities/repositories/ChatBot";
import DynamicLayout from "./features/DynamicLayout";
import toast from "react-hot-toast";
import { useAdvanceFeatures } from "@/hooks/use-advance-features";
import LandingPage from "./features/LandingPage";
import MultipleContactPage from "./features/MultipleContact";
import TeamAccountPage from "./features/TeamAccount";

export default function AdvancedFeaturesPages() {
  const [tabIndex, setTabIndex] = useState(0);
  const { setDynamicLayoutMode } = useAdvanceFeatures();

  const { profile } = useProfile();
  const form = useForm({
    resolver: zodResolver(advanceFeatures),
    defaultValues: {
      telegramBot: {
        chatId: "",
        type: EFeatureType.IpAccessTrackerTelegram,
      },
      dynamicLayout: {
        type: EFeatureType.DynamicLayout,
        enable: false,
        dynamicLayoutMode: EFeatureMode.Normal,
      },
      landingPage: {
        type: EFeatureType.LandingPage,
        enable: false,
      },
      multipleContact: {
        enable: false,
        type: EFeatureType.MultipleContact,
      },
      teamAccount: {
        enable: false,
        type: EFeatureType.TeamAccount,
      },
    },
  });

  const renderTabPanelByFeatureType = (featureType: EFeatureType) => {
    switch (featureType) {
      case EFeatureType.IpAccessTrackerTelegram:
        return <TelegramConfigurationForm name="telegramBot" />;
      case EFeatureType.DynamicLayout:
        return <DynamicLayout name="dynamicLayout" />;
      case EFeatureType.LandingPage:
        return <LandingPage name="landingPage" />;
      case EFeatureType.MultipleContact:
        return <MultipleContactPage name="multipleContact" />;
      case EFeatureType.TeamAccount:
        return <TeamAccountPage name="teamAccount" />;

      default:
        return <></>;
    }
  };

  const { request: getFeatureByUserId, response: featuresByUserId } = useApi<
    IResponseData<IUserFeature[]>
  >({
    request: AdvanceFeatureRepository.getUserFeaturesById,
  });

  const { request: getChatBotSetting, response: chatBotResponse } = useApi<any>(
    {
      request: ChatBotRepositories.getAllChatBot,
    }
  );

  const { request: updateDynamicLayoutMode } = useApi({
    request: AdvanceFeatureRepository.updateDynamicLayoutMode,
    enableToast: true,
  });

  const { request: updateSettingChatBox } = useApi({
    request: ChatBotRepositories.settingChatBot,
    enableToast: true,
  });

  useEffect(() => {
    if (profile?.id) {
      getFeatureByUserId(profile?.id).then((response) => {
        const userFeatureIpAccessTrackerTelegram = response?.data?.find(
          (userFeature) =>
            userFeature?.feature?.type === EFeatureType.IpAccessTrackerTelegram
        );
        if (userFeatureIpAccessTrackerTelegram) {
          getChatBotSetting();
        }
        const userFeatureDynamicLayout = response?.data?.find(
          (userFeature) =>
            userFeature?.feature?.type === EFeatureType.DynamicLayout
        );
        const userFeatureLandingPage = response?.data?.find(
          (userFeature) =>
            userFeature?.feature?.type === EFeatureType.LandingPage
        );
        const userFeatureMultipleContact = response?.data?.find(
          (userFeature) =>
            userFeature.feature.type === EFeatureType.MultipleContact
        );
        const userFeatureTeamAccount = response?.data?.find(
          (userFeature) => userFeature.feature.type === EFeatureType.TeamAccount
        );
        form.reset({
          telegramBot: {
            chatId: userFeatureIpAccessTrackerTelegram?.chatId || "",
            type: EFeatureType.IpAccessTrackerTelegram,
          },
          dynamicLayout: {
            enable: userFeatureDynamicLayout?.enabled || false,
            dynamicLayoutMode:
              userFeatureDynamicLayout?.dynamicLayoutMode ||
              EFeatureMode.Normal,
            type: EFeatureType.DynamicLayout,
          },
          landingPage: {
            enable: userFeatureLandingPage?.enabled || false,
            type: EFeatureType.LandingPage,
          },
          multipleContact: {
            enable: userFeatureMultipleContact?.enabled || false,
            type: EFeatureType.MultipleContact,
          },
          teamAccount: {
            enable: userFeatureTeamAccount?.enabled || false,
            type: EFeatureType.TeamAccount,
          },
        });
      });
    }
  }, [form, getChatBotSetting, getFeatureByUserId, profile?.id]);

  const onSubmit = async (values: AdvanceFeaturesSchema) => {
    const { telegramBot, dynamicLayout } = values;

    const submittedFeature = featuresByUserId?.data[tabIndex];
    if (!submittedFeature) return toast.error("Feature not found");

    switch (submittedFeature?.feature?.type) {
      case EFeatureType.IpAccessTrackerTelegram: {
        const telegramChatBotData: ChatBotDTO = {
          chatId: telegramBot?.chatId || "",
          email: profile?.email || "",
          userId: profile?.id || "",
          name:
            (import.meta.env.VITE_BASE_TELEGRAM_BOT_NAME as string) ||
            "property-development-tele-bot",
        };
        const currentSettingChatBot = chatBotResponse?.data?.[0];

        await updateSettingChatBox(
          telegramChatBotData,
          currentSettingChatBot?.id
        );

        break;
      }
      case EFeatureType.DynamicLayout: {
        const featureDynamicLayout = profile?.userFeatures?.find(
          (feature) => feature.id === submittedFeature.id
        );

        if (!featureDynamicLayout) return toast.error("Feature not found");

        try {
          updateDynamicLayoutMode(featureDynamicLayout.id, {
            dynamicLayoutMode:
              dynamicLayout?.dynamicLayoutMode || EFeatureMode.Normal,
          });

          setDynamicLayoutMode(
            dynamicLayout?.dynamicLayoutMode || EFeatureMode.Normal
          );
        } catch (error) {
          toast(error?.toString() || "Update failed");
        }

        break;
      }

      case EFeatureType.LandingPage: {
        const featureLandingPage = profile?.userFeatures?.find(
          (feature) => feature.id === submittedFeature.id
        );

        if (!featureLandingPage) return toast.error("Feature not found");

        break;
      }
      case EFeatureType.MultipleContact: {
        const featureMultipleContact = profile?.userFeatures?.find(
          (feature) => feature.id === submittedFeature.id
        );

        if (!featureMultipleContact) return toast.error("Feature not found");

        break;
      }
      case EFeatureType.TeamAccount: {
        const featureTeamAccount = profile?.userFeatures?.find(
          (feature) => feature.id === submittedFeature.id
        );

        if (!featureTeamAccount) return toast.error("Feature not found");

        break;
      }
      default: {
        break;
      }
    }
  };
  return (
    <div className="relative h-full z-50 my-4 flex flex-wrap items-center justify-between gap-2.5 @container">
      <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
        Telegram Setting
      </Title>
      <FormProvider {...form}>
        <form
          className="flex flex-col w-full h-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          {/* Use map to iterate over the filtered features and render the relevant component */}
          {featuresByUserId?.data?.map((item) => {
            if (item?.feature?.type === EFeatureType.IpAccessTrackerTelegram) {
              // Render the Tab Panel only if the condition is met
              return renderTabPanelByFeatureType(item?.feature?.type);
            }

            // Return null if not matching
            return null;
          })}

          {/* Save Button */}
          <div
            className={cn(
              "col-span-full flex items-center justify-start gap-4 bg-white p-2 sticky bottom-0 left-0 right-0 z-[99]"
            )}
          >
            <Button
              type="submit"
              id={"form-settings"}
              className="w-full @xl:w-auto"
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
