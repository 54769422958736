import ControlledTable from "@/components/controlled-table";
import { useColumn } from "@/hooks/use-column.ts";
import { Title } from "rizzui";
import { GetColumns } from "./columns";
import RoundRobinRepository from "@/utilities/repositories/RoundRobin";
import useProfile from "@/hooks/use-profile";
import { useTableServer } from "@/hooks/useTableServer";

const RoundRobinHistoryTable = () => {
  const { profile } = useProfile();

  const {
    // handleReset,
    isLoading,
    tableData,
    currentPage,
    totalItems,
    // handleSort,
    handlePaginate,
    pageSize,
    handleChangePageSize,
    refresh,
  } = useTableServer({
    server: {
      request: RoundRobinRepository.distributedHistory,
    },
    initialFilterState: {
      id: profile?.id,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
  });

  const columns = GetColumns({
    profile,
    // or specify your form's type, e.g., FormData
  });

  const { visibleColumns } = useColumn<any>(columns);

  return (
    <div className={"col-span-full mt-4"}>
      <div className="mb-4 w-full flex flex-col lg:flex-row lg:items-center items-start gap-2.5 @container justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          Distributed History
        </Title>
      </div>
      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        columns={visibleColumns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
};

export default RoundRobinHistoryTable;
