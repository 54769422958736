import RoundRobinTable from "@/app/app-shared/round-robin";
import RoundRobinHistory from "@/app/app-shared/round-robin/history";

const RoundRobinPage = () => {
  return (
    <div className="flex flex-col gap-3">
      <RoundRobinTable />
      <RoundRobinHistory />
    </div>
  );
};

export default RoundRobinPage;
