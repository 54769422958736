import { request } from "@/utilities/libs/request.ts";
import { IRequestArgs, IResponseData } from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { E_USERS } from "@/utilities/enums/Users.ts";
import {
  CreateUserDto,
  IUserData,
  IUserDataResponse,
  TSetPasswordDto,
} from "@/utilities/types/Users.ts";

function getUsers({ queryParams }: IRequestArgs) {
  const query = {
    filter: queryParams?.query?.filter,
    sort: queryParams?.query?.sort,
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
    projectId: queryParams?.query?.projectId,
  };
  return request<IResponseData<IUserDataResponse>>({
    method: ERequestMethods.GET,
    url: E_USERS.MAIN,
    params: query,
  });
}

function getTeamUsers({ queryParams }: IRequestArgs) {
  const query = {
    filter: queryParams?.query?.filter,
    sort: queryParams?.query?.sort,
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
    projectId: queryParams?.query?.projectId,
  };
  return request<IResponseData<IUserDataResponse>>({
    method: ERequestMethods.GET,
    url: E_USERS.MY_USERS,
    params: query,
  });
}

function getUserById(id: string) {
  return request<IResponseData<IUserData>>({
    method: ERequestMethods.GET,
    url: E_USERS.USER_DETAIL.replace(":id", id),
  });
}

function getMe() {
  return request<IResponseData<IUserData>>({
    method: ERequestMethods.GET,
    url: E_USERS.ME,
  });
}

function updateMe(data: Partial<CreateUserDto>) {
  return request<IResponseData<IUserData>>({
    method: ERequestMethods.PATCH,
    url: E_USERS.ME,
    data,
  });
}

function createUser(data: CreateUserDto) {
  return request<IResponseData<IUserDataResponse>>({
    method: ERequestMethods.POST,
    url: E_USERS.MAIN,
    data,
  });
}

function updateUser(id: string, data: Partial<CreateUserDto>) {
  return request<IResponseData<IUserDataResponse>>({
    method: ERequestMethods.PATCH,
    url: E_USERS.USER_DETAIL.replace(":id", id),
    data,
  });
}

function deleteUser(id: string) {
  return request<IResponseData<IUserDataResponse>>({
    method: ERequestMethods.DELETE,
    url: E_USERS.USER_DETAIL.replace(":id", id),
  });
}

function setPassword(data: Partial<TSetPasswordDto>) {
  return request<IResponseData<TSetPasswordDto>>({
    method: ERequestMethods.POST,
    url: E_USERS.SET_PASSWORD,
    data,
  });
}

const UserRepository = Object.freeze({
  getUsers,
  getTeamUsers,
  getUserById,
  createUser,
  updateUser,
  deleteUser,
  updateMe,
  getMe,
  setPassword,
});

export default UserRepository;
