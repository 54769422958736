import { IDeveloper } from "./Developer";
import { ILocale } from "./Locale";
import { ILocation } from "./Location";
import { IPhoto } from "./Photo";
import { IUrls, UploadType } from "./Upload";
export enum ETenure {
  Freehold = "freehold",
  Yr99 = "99-yr",
  Yr101 = "101-yr",
  Yr999 = "999-yr",
}
export enum EMarketSegment {
  RCR = "RCR",
  CCR = "CCR",
  OCR = "OCR",
}
export interface ICategory {
  id: string;
  slug: string;
  name: string;
  shortname?: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
}

export interface IUserProject {
  id: string;
  featured: boolean;
  promotion: boolean;
  weight: number;
  upcomingLaunch: boolean | null;
  isShow: boolean; // default : true
}

export interface IProject {
  id: string;
  slug: string;
  marketSegment: EMarketSegment;
  coordinates: string;
  area: number;
  expectedTop: string;
  tenure: ETenure;
  tenureEffectFrom: Date;
  name: ILocale | string;
  description: string;
  address: string;
  photo: IPhoto;
  developer: IDeveloper | null;
  category: ICategory;
  location: Modify<
    ILocation,
    {
      name: ILocale | string;
    }
  >;
  userProjects: IUserProject[] | null;
  floorPlanCount?: number;
  totalUnitsCount?: number;
  availableUnitsCount?: number;
  manualTotalUnitsCount?: number;
  manualAvailableUnitsCount?: number;
  upcomingLaunch?: boolean;
  userProject: {
    featured: boolean;
    id: string;
    promotion: boolean;
    weight: number;
  };
  directCommission: number;
  googleMapUrl: string;
  promotion: boolean;
  isCommissionUpTo: boolean;
  featured: boolean;
}

export interface IProjectLocale
  extends Modify<
    IProject,
    { name: ILocale | string; description: ILocale | string; address: ILocale }
  > {}

export interface IProjectDTO {
  name: string;
  area: number;
  tenure: ETenure;
  photoId: string;
  categoryId: string;
  address?: string;
  description: string;
  marketSegment: EMarketSegment;
  locationId: string;
  medias: string[]; //  Id from the upload response
  developerId?: string;
  googleMapUrl?: string;
  status?: string; // by-buc | top-soon | completed
  expectedTop?: Date;
  tenureEffectFrom?: Date;
  upcomingLaunch?: boolean;
  directCommission?: number;
  promotion?: boolean;
  manualTotalUnitsCount?: number;
  manualAvailableUnitsCount?: number;
}

export interface MediaProject {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  type: UploadType;
  relation: string;
  relationId: string;
  urls: IUrls[];
}

export interface IProjectDetail extends IProject {
  medias: MediaProject[];
  facilityHtml: string;
  amenityHtml: string;
}

export interface AddManyUserProjectDTO {
  projectId: string;
  weight: number;
  featured?: boolean;
  promotion?: boolean;
}
