import FormGroup from "@/app/shared/form-group";
import UploadZone from "@/components/ui/file-upload/upload-zone";
import cn from "@/utils/class-names";
import { useFormContext } from "react-hook-form";

export interface MediasPartProps {
  className?: string;
}

export default function MediasPart({ className }: MediasPartProps) {
  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="flex flex-col gap-10">
      <FormGroup
        title="Thumbnail"
        description="Accept all type of images"
        className={cn(className)}
      >
        <UploadZone
          name="pictures"
          className="col-span-full upload-zone"
          getValues={getValues}
          setValue={setValue}
          error={errors?.pictures?.message as string}
          inputProps={{
            multiple: false,
            accept: "image/*",
          }}
        />
      </FormGroup>
      <FormGroup
        title="Medias"
        description="Accept all type of images and videos"
        className={cn(className)}
      >
        <UploadZone
          name="medias"
          className="col-span-full upload-zone"
          getValues={getValues}
          setValue={setValue}
          error={errors?.medias?.message as string}
          inputProps={{
            accept: "image/*,video/*",
            multiple: true,
          }}
        />
      </FormGroup>
    </div>
  );
}
